import { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Dialog } from "@mui/material";

import { apiMan } from "@elx-element/common/apiClient/apiMan";
import { Notification } from "@elx-element/common/enums";
import { dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import { NotificationEventModel } from "@elx-element/common/events/types";
import { LogRecord } from "@elx-element/common/logger/types";
import { getLogFromSessionStorage } from "@elx-element/common/logger/utils";

import { WebContainerClient, WikiResultModel } from "../../types.generated";

import { setOpenErrorDetailReference } from "../../store/core/action";
import { selectOpenErrorDetailReference, selectTexts } from "../../store/selectors";

import useStyles from "./styles";

import ConfluenceComponent from "./ConfluenceComponent";
import ErrorComponent from "./ErrorComponent";
import JiraComponent from "./JiraComponent";

const ErrorDetail = () => {
  const dispatch = useDispatch();
  const texts = useSelector(selectTexts);
  const { classes } = useStyles();
  const openErrorDetailReference = useSelector(selectOpenErrorDetailReference);

  const [loading, setLoading] = useState(false);
  const [jiraDialogOpen, setJiraDialogOpen] = useState(false);
  const [confluenceDialogOpen, setConfluenceDialogOpen] = useState(false);
  const [document, setDocument] = useState<WikiResultModel>();
  const [error, setError] = useState<LogRecord | undefined>(undefined);
  const [jiraButtonDisabled, setJiraButtonDisabled] = useState(true);

  const abortController = useRef(new AbortController());
  useEffect(
    () => () => {
      abortController.current.abort();
    },
    []
  );

  useEffect(() => {
    if (openErrorDetailReference) {
      setError(getLogFromSessionStorage().find(x => x.storageId === Number.parseInt(openErrorDetailReference)));
    } else {
      setJiraDialogOpen(false);
      setConfluenceDialogOpen(false);
      setError(undefined);
    }
  }, [openErrorDetailReference]);

  const knowledgeBaseClick = async (errorCode: string) => {
    setLoading(true);
    const result = await apiMan(
      new WebContainerClient({ abortSignal: abortController.current.signal }).getErrorDocuments(
        `${errorCode.replace(/\./g, "-")}`
      )
    );
    if (result && result.length > 0 && !abortController.current.signal.aborted) {
      setDocument(result[0]);
      setConfluenceDialogOpen(true);
    } else {
      dispatchNotificationEvent(new NotificationEventModel(texts.ERROR_HELP_IS_MISSING, Notification.info));
    }
    setLoading(false);
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {error && (
        <Dialog open className={classes.errorBox} id="webcontainer_errorBox">
          {!jiraDialogOpen && !confluenceDialogOpen && (
            <ErrorComponent
              jiraButtonDisabled={jiraButtonDisabled}
              setJiraButtonDisabled={setJiraButtonDisabled}
              loading={loading}
              error={error}
              onClose={() => dispatch(setOpenErrorDetailReference(undefined))}
              knowledgeBaseClick={knowledgeBaseClick}
              onJiraButtonClick={() => setJiraDialogOpen(true)}
            />
          )}
          {jiraDialogOpen && (
            <JiraComponent
              onBack={() => setJiraDialogOpen(false)}
              onClose={() => dispatch(setOpenErrorDetailReference(undefined))}
              error={error}
            />
          )}
          {confluenceDialogOpen && document && (
            <ConfluenceComponent onBack={() => setConfluenceDialogOpen(false)} document={document} />
          )}
        </Dialog>
      )}
    </>
  );
};

export default ErrorDetail;
