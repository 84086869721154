import { useSelector } from "react-redux";

import Button from "@mui/material/Button";

import { selectTexts } from "../../store/selectors";

import History from "../../history";

const TryLaterPage = () => {
  const texts = useSelector(selectTexts);
  return (
    <div>
      <div>
        {texts.LOGIN_ERROR} {texts.TRY_IT_LATER}
      </div>
      <br />
      <Button variant="contained" onClick={() => History.push("/")}>
        {texts.TRY_AGAIN}
      </Button>
    </div>
  );
};

export default TryLaterPage;
