import { Dispatch } from "react";

import { CameraPreviewOptions, ReplaceRule, ScanOptions, scanResult } from "capacitor-elinkx-machine-learning/src";

import { EventCode, ScanType } from "@elx-element/common/enums";
import { getConfigurationBoolValue } from "@elx-element/common/envconf";
import { dispatchScannerEvent } from "@elx-element/common/events/scanner";
import { IScan, ScannerControl } from "@elx-element/common/events/types";
import { throwSpecificError } from "@elx-element/common/logger";
import { getLastGpsLocation } from "@elx-element/common/storage";

import { PluginListenerHandle, Plugins } from "@capacitor/core";

import * as CoreStoreTypes from "../../store/core/types";

import { clearScanner, configureScanner, setScannerLastScan } from "../../store/core/action";

import { hideScannerPageBackground, showScannerPageBackground } from "./scannerCore";

import { WebContainerErrors } from "../../enums";

const { MachineLearning } = Plugins;
const debug = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_SCANNER_DEBUG");

// základní konfigurace nahledu kamery
const cameraPreviewOptions: CameraPreviewOptions = {
  position: "rear",
  parent: "scanButtonContainer",
  className: "scanButtonContainer",
  toBack: true,
};

// handle eventu skenování pro potřebu ukončení naslouchání
let scanHandle: PluginListenerHandle;

/**
 * vrací konfiguraci podle aktuálního požadavku skenování
 * @param settings
 */
const getMachineLearningScanerOptions = (settings: ScannerControl): ScanOptions => {
  // udělám konverzi interface na interface pluginu
  const options: ScanOptions = {
    type: getCammeraScanOptionType(settings.type),
  };
  return options;
};

/** Konverze typu skenu */
const getCammeraScanOptionType = (t: ScanType) => {
  switch (t) {
    case ScanType.cameraOcr:
      return "ocr";
    case ScanType.cameraBarcode:
      return "barcode";
    case ScanType.cameraQr:
      return "qr";
    default:
      return "photo";
  }
};

/**
 * Po naskenování hodnoty volá event ScannerEvents.dispatchScannerEvent(event: Common.EventModels.IScanEvent)
 * Cílový modul událost zpracuje a může sám zavřít skenování, nebo nereagovat.
 * Pokud nereaguje pak může uživatel ručně vnutit naskenovaný kód tlačítkem "Použít".
 */
export const onScanPerformed = (dispatch: Dispatch<CoreStoreTypes.setScannerLastScan>, scan: IScan) => {
  // Příklad nastavení naskenovaného neznámého kódu
  dispatch(
    setScannerLastScan(
      scan /* {type: Enums.ScanType.ocr, gps: "49.56078023021972, 18.79304083346614", value: "1M8GDM9AXKP042788"} */
    )
  );
  dispatchScannerEvent({ type: EventCode.scanPerformed, scan });
};

/**
 * Po naskenování hodnoty volá event ScannerEvents.dispatchScannerEvent(event: Common.EventModels.IScanEvent)
 * Cílový modul zpracuje událost ,scanner se uzavírá automaticky skrz typ události Enums.EventCode.scanFinished
 */
export const onScanFinished = (dispatch: Dispatch<CoreStoreTypes.setScannerLastScan>, scan: IScan) => {
  // Příklad nastavení naskenovaného neznámého kódu
  dispatch(
    setScannerLastScan(
      scan /* {type: Enums.ScanType.ocr, gps: "49.56078023021972, 18.79304083346614", value: "1M8GDM9AXKP042788"} */
    )
  );
  dispatchScannerEvent({ type: EventCode.scanFinished, scan });
};

/**
 * posluchač změny state s nastavením skeneru
 * @param settings - nastavení skeneru ScannerControl
 * @param dispatch
 */
export const handleCameraScannerControlEvent = (
  settings: CustomEventInit<ScannerControl>,
  dispatch: Dispatch<CoreStoreTypes.configureScanner | CoreStoreTypes.clearScanner | CoreStoreTypes.setScannerLastScan>
) => {
  // Příchozí prametry
  // settings.detail!.active (boolean)
  // settings.detail!.name   (string | undefined)
  // settings.detail!.regex  (string | undefined)
  // settings.detail!.type   (Enums.ScanType)

  // spuštění skeneru
  if (settings.detail!.active) {
    scannerOpen(settings.detail!, dispatch);
  } else {
    // zavření skeneru
    scannerClose(dispatch);
  }
};

/**
 * obsluha události změny orientace skeneru (kamery).
 */
const deviceOrientationChange = () => {
  MachineLearning.isCameraActive().then((result: { value: boolean }) => {
    if (result.value) {
      if (debug) {
        console.debug("scanner: restart kamery při změně orientace obrazovky");
      }
      try {
        // vypnu a zapnu kameru
        MachineLearning.stop().then(() => {
          MachineLearning.start(cameraPreviewOptions);
        });
      } catch (ex) {
        throwSpecificError(
          WebContainerErrors.scannerOrientationChangeError,
          "Scanner: camera restart failed when changing orientation",
          ex as string
        );
      }
    }
  });
};

/**
 * obsluha události naskenování nového výsledku
 * pro OCR se provádí ve třech fázích - 1.nahrazení výsledku dle kolekce RegExp pravidel, 2.rozpoznání obsahu dle kontrolního RegExp, 3.vyhodnocení výsledku
 * @param result - výsledek skenování typu scanResult
 * @param settings - state nastavení scaneru
 * @param dispatch
 * @param onScanCompleted - funkce pro uložení výsledku skenování
 * @param onScanFinished - funkce pro uložení výsledku skenování s uzavřením scanneru
 */
const scanResultFunction = (
  result: scanResult,
  settings: ScannerControl,
  dispatch: Dispatch<CoreStoreTypes.setScannerLastScan>,
  onScanCompleted: (dispatch: Dispatch<CoreStoreTypes.setScannerLastScan>, scan: IScan) => void,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  onScanFinished: (dispatch: Dispatch<CoreStoreTypes.setScannerLastScan>, scan: IScan) => void
) => {
  if (debug) {
    console.debug(`scanner: scanned value: ${JSON.stringify(result)}`);
  }

  if (result.result === "ok") {
    let lastGpsLocationString: string | undefined;

    // získám poslední známou polohu z localStorage
    const lastGpsLocation = getLastGpsLocation();
    if (lastGpsLocation) {
      lastGpsLocationString = `${lastGpsLocation.position}, acc: ${parseInt(lastGpsLocation.accuracy!)}`;
    }

    if (debug) {
      console.debug(`data: ${result.data}`);
    }

    if (result.type === ScanType.cameraOcr.valueOf()) {
      let { data } = result;

      // 1. upravíme data dle nastavených pravidel
      if (settings && settings.replaceRules) {
        try {
          const rules = settings.replaceRules as Array<ReplaceRule>;
          rules.forEach(rule => {
            if (rule.key === "(UPPERCASE)") {
              // speciální pravidlo pro UPPERCASE
              data = data.toUpperCase();
            } else {
              const regexp = new RegExp(rule.key, "g");
              data = data.replace(regexp, rule.value);
            }

            if (debug) {
              console.debug(`RegExp rule applied: '${rule.key}' -> '${rule.value}' result: ${data}`);
            }
          });
        } catch (ex) {
          throwSpecificError(
            WebContainerErrors.scannerRegexpReplaceError,
            "Scanner: result regexp replace error",
            ex as string
          );
        }
      }

      // 2. detekujeme shodu s požadovaným regexpem (výsledkem je pole hodnot)
      let results: RegExpMatchArray | null = null;
      try {
        if (!settings.regex) {
          throw Error("Regexp definition in scanner settings is undefined");
        }
        if (debug) {
          console.debug(`scanner: regexp ${settings.regex}`);
        }

        const regex = new RegExp(settings.regex, "g");
        results = data.match(regex);
        if (debug) {
          console.debug(`scanner: regexp result ${JSON.stringify(results)}`);
        }
      } catch (ex) {
        throwSpecificError(WebContainerErrors.scannerRegexpError, "Scanner: scanner regexp error", ex as string);
      }

      // 3. zpracování výsledků
      if (results !== null && results.length > 0) {
        let retVal: string | undefined;
        if (results.length > 0) {
          // použiju vždy první detekovanou hodnotu
          [retVal] = results;
        }

        // 4. odeberu z výsledku mezery
        // V momentě kdy bude potřeba skenovat data s mezerami, zavedeme novou sadu pravidel
        // obdobně jako v settings.replaceRules pro převod naskonované hodnoty na dataFormat.
        if (retVal != null) {
          retVal = retVal.replace(/\s/g, "");
        }
        if (debug) {
          console.debug(`scanner: result:${retVal}`);
        }

        onScanCompleted(dispatch, {
          name: settings.name,
          type: ScanType.cameraOcr,
          gps: lastGpsLocationString,
          value: retVal,
        });
      } else {
        // opakovaný scann, v případě nenalezení dat
        try {
          if (debug) {
            console.debug("scanner: regexp not detected, automatic retry attempt");
          }
          MachineLearning.scan(getMachineLearningScanerOptions(settings));
        } catch (ex) {
          throwSpecificError(
            WebContainerErrors.scannerNotFoundScanError,
            "Scanner: error new scann try failed",
            ex as string
          );
        }
      }
    }

    // zpracování fotky
    if (result.type === ScanType.cameraPhoto.valueOf()) {
      onScanFinished(dispatch, {
        name: settings.name,
        type: ScanType.cameraPhoto,
        gps: lastGpsLocationString,
        value: result.data,
      });
    }

    // zpracování barcode výsledku
    if (result.type === ScanType.cameraBarcode.valueOf()) {
      if (result !== null && result.data.length > 0) {
        if (debug) {
          console.debug(`scanner: barcode result:${result.data}`);
        }

        onScanCompleted(dispatch, {
          name: settings.name,
          type: ScanType.cameraBarcode,
          gps: lastGpsLocationString,
          value: result.data,
        });
      } else {
        // opakovaný scann, v případě prázdných dat (Firebase může při prvním scanu vrátit prázdný výsledek)
        try {
          if (debug) {
            console.debug("scanner: barcode not detected, automatic retry attempt");
          }
          MachineLearning.scan(getMachineLearningScanerOptions(settings));
        } catch (ex) {
          throwSpecificError(
            WebContainerErrors.scannerNotFoundScanError,
            "Scanner: error new barcode scann try failed",
            ex as string
          );
        }
      }
    }

    // zpracování 2d barcode / qr code výsledku
    if (result.type === ScanType.cameraQr.valueOf()) {
      if (result !== null && result.data.length > 0) {
        if (debug) {
          console.debug(`scanner: barcode result:${result.data}`);
        }

        onScanCompleted(dispatch, {
          name: settings.name,
          type: ScanType.cameraQr,
          gps: lastGpsLocationString,
          value: result.data,
        });
      } else {
        // opakovaný scann, v případě prázdných dat (Firebase může při prvním scanu vrátit prázdný výsledek)
        try {
          if (debug) {
            console.debug("scanner: 2d barcode / qr code not detected, automatic retry attempt");
          }
          MachineLearning.scan(getMachineLearningScanerOptions(settings));
        } catch (ex) {
          throwSpecificError(
            WebContainerErrors.scannerNotFoundScanError,
            "Scanner: error new 2d barcode / qr code scann try failed",
            ex as string
          );
        }
      }
    }
  }
};

/**
 * opakované spuštění scanneru, pokud je dostupné nastavení
 */
export const scanAgain = (settings: ScannerControl | undefined) => {
  if (debug) {
    console.debug("scanner: opakované skenování tlačítkem");
  }
  try {
    if (settings) {
      // krátkodobý test scanu fotky
      const opt = getMachineLearningScanerOptions(settings);
      MachineLearning.scan(opt);
    }
  } catch (ex) {
    throwSpecificError(WebContainerErrors.scannerScanAgainError, "Scanner: error new scann try failed", ex as string);
  }
};

/**
 * otevření náhledu scanneru a zahájení prvního skenování dle nastavení
 * @param settings - nastavení skenování
 * @param dispatch
 */
export const scannerOpen = (
  settings: ScannerControl,
  dispatch: Dispatch<CoreStoreTypes.configureScanner | CoreStoreTypes.setScannerLastScan>
) => {
  dispatch(configureScanner(settings));
  hideScannerPageBackground();

  // inicializace posluchače skenování a otáčení obrazovky
  scanHandle = MachineLearning.addListener("scanEvent", (result: scanResult) =>
    scanResultFunction(result, settings, dispatch, onScanPerformed, onScanFinished)
  );
  window.addEventListener("orientationchange", deviceOrientationChange);

  // nastartování scanneru a spuštění skenování
  try {
    MachineLearning.isCameraActive().then((result: { value: boolean }) => {
      if (result.value) {
        if (settings.type !== ScanType.cameraPhoto) {
          MachineLearning.scan(getMachineLearningScanerOptions(settings));
        }
      } else {
        MachineLearning.start(cameraPreviewOptions).then(() => {
          if (settings.type !== ScanType.cameraPhoto) {
            MachineLearning.scan(getMachineLearningScanerOptions(settings));
          }
        });
      }
    });
  } catch (ex) {
    throwSpecificError(WebContainerErrors.scannerNotFoundScanError, "Scanner: scan error", ex as string);
  }
};

/**
 * zavření náhledu scanneru
 * @param dispatch
 */
export const scannerClose = (dispatch: Dispatch<CoreStoreTypes.clearScanner>) => {
  // odebrání obou posluchačů
  window.removeEventListener("orientationchange", deviceOrientationChange);

  if (scanHandle != null) {
    scanHandle.remove();
  }

  // zastavení scann pluginu pokud je aktivní
  MachineLearning.isCameraActive().then((result: { value: boolean }) => {
    if (result.value) {
      if (debug) {
        console.debug("scanner: Probíhá vypnutí kamery při uzavření skeneru.");
      }
      try {
        // vypnu kameru
        MachineLearning.stop();
      } catch (ex) {
        throwSpecificError(WebContainerErrors.scannerShutdownError, "Scanner: scanner shutdown error", ex as string);
      }
    }
  });

  dispatch(clearScanner());
  showScannerPageBackground();
};
