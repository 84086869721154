import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";

import { useElementContext } from "@elx-element/common/elementContext";
import { CommonErrors, NetworkStatus } from "@elx-element/common/enums";
import { LogRecord } from "@elx-element/common/logger/types";
import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";

import { mdiBookOpenPageVariantOutline, mdiBugOutline, mdiClose } from "@mdi/js";

import { setOpenErrorDetailReference } from "../../store/core/action";
import { selectNetworkStatus, selectTexts } from "../../store/selectors";

import useStyles from "./styles";

interface ErrorContentProps {
  error: LogRecord;
  loading: boolean;
  jiraButtonDisabled: boolean | undefined;
  // eslint-disable-next-line react/no-unused-prop-types
  onClose: () => void;
  knowledgeBaseClick: (errorCode: string) => void;
  onJiraButtonClick: () => void;
  setJiraButtonDisabled: (disabled: boolean) => void;
}

const ErrorComponent = (props: ErrorContentProps) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const texts = useSelector(selectTexts);
  const networkStatus = useSelector(selectNetworkStatus);
  const isOnline = networkStatus === NetworkStatus.online;
  const { formatDateTime } = useElementContext();

  let isSpecificError = true;
  const errorType = props.error.errorCode!.substring(props.error.errorCode!.lastIndexOf(".") + 1);
  if (Object.keys(CommonErrors).includes(errorType)) {
    isSpecificError = false;
  }
  return (
    <>
      <DialogTitle className={classes.errorTitle}>
        {texts.ERROR_MESSAGE}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          className={classes.closeButton}
          onClick={() => dispatch(setOpenErrorDetailReference(undefined))}
        >
          <BaseIcon data={mdiClose} />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.errorContent}>
        <Typography variant="body1">
          <small>
            <b>{texts.MODULE}</b>
          </small>
          : {props.error.application}
        </Typography>
        <Typography variant="body1">
          <small>
            <b>{texts.DATETIME}</b>
          </small>
          : {formatDateTime(props.error.date)}
        </Typography>
        <Typography variant="body1">
          <small>
            <b>{texts.ERROR_CODE}</b>
          </small>
          : {props.error.errorCode}
        </Typography>
        {props.error.message && (
          <>
            <br />
            <Typography variant="body1">
              <small>
                <b>{texts.DETAIL}</b>
              </small>
              : {props.error.message}
            </Typography>
          </>
        )}
        {isOnline && !isSpecificError && (
          <>
            <br />
            <Typography variant="body1">{texts.ERROR_UNKNOWN_OCCURED}</Typography>
          </>
        )}
        {isOnline && isSpecificError && (
          <>
            <br />
            <Typography variant="body1">{texts.ERROR_SPECIFIC_OCCURED}</Typography>
          </>
        )}
        {!isOnline && (
          <>
            <br />
            <Typography variant="body1">{texts.ERROR_TRY_IT_LATER}</Typography>
          </>
        )}
      </DialogContent>

      <DialogActions className={classes.errorFooter}>
        {isOnline && (
          <>
            <Button
              color="primary"
              variant={isSpecificError ? "outlined" : "contained"}
              disabled={(isSpecificError === false ? undefined : props.jiraButtonDisabled) || props.loading}
              startIcon={<BaseIcon data={mdiBugOutline} />}
              onClick={props.onJiraButtonClick}
            >
              {texts.REPORT_TO_JIRA}
            </Button>

            {isSpecificError && (
              <Button
                color="secondary"
                variant="contained"
                disabled={props.loading}
                startIcon={
                  props.loading ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : (
                    <BaseIcon data={mdiBookOpenPageVariantOutline} />
                  )
                }
                onClick={() => {
                  props.setJiraButtonDisabled(false);
                  props.knowledgeBaseClick(props.error.errorCode!);
                }}
              >
                {texts.KNOWLEDGE_BASE}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </>
  );
};

export default ErrorComponent;
