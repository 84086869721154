import React from "react";

import { useSelector } from "react-redux";

import { Tooltip, Typography } from "@mui/material";

import { useElementContext } from "@elx-element/common/elementContext";
import { getModulesAlerts } from "@elx-element/common/storage";
import { format } from "@elx-element/common/utils";
import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";

import { mdiAlertOctagon } from "@mdi/js";

import { selectRegisteredModules, selectTexts } from "../../store/selectors";

import useStyles from "./styles";

interface IncompatibilityIconProps {
  moduleId: string;
  showOptionalAlerts: boolean;
}

const IncompatibilityIcon = (props: IncompatibilityIconProps) => {
  const { classes } = useStyles();
  const texts = useSelector(selectTexts);
  const registeredModules = useSelector(selectRegisteredModules);
  const { formatDate } = useElementContext();

  const module = registeredModules.find(x => x.moduleId === props.moduleId);
  const moduleUncompatibilityAlerts = getModulesAlerts().filter(x => x.moduleId === props.moduleId);
  const containsAnyAlerts = moduleUncompatibilityAlerts.length > 0;
  const containsRequiredAlerts = moduleUncompatibilityAlerts.some(x => x.required);
  const moduleLicenseExpiring = module?.licenseExpiring();
  const moduleLicenseExpired = module?.licenseExpired();

  return (
    <div>
      {((props.showOptionalAlerts && containsAnyAlerts) ||
        containsRequiredAlerts ||
        moduleLicenseExpiring ||
        moduleLicenseExpired) && (
        <Tooltip
          placement="bottom-start"
          title={
            <React.Fragment>
              {(moduleLicenseExpiring || moduleLicenseExpired) && (
                <>
                  <Typography variant="body2">
                    <b>{texts.LICENSE_PROBLEM_TITLE}</b>
                  </Typography>
                  <Typography variant="body2">
                    {moduleLicenseExpiring
                      ? format(texts.LICENSE_EXPIRING, formatDate(module!.licenseExpiration!))
                      : format(texts.LICENSE_EXPIRED, "")}
                  </Typography>
                </>
              )}

              {containsAnyAlerts && (
                <div className={classes.alertsList}>
                  <Typography variant="body2">
                    <b>{texts.INCOMPATIBILITY}</b>
                  </Typography>

                  <ul>
                    {moduleUncompatibilityAlerts.map((event, index) => (
                      <li key={index}>
                        <Typography variant="body2">{event.alertMessage}</Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </React.Fragment>
          }
        >
          <BaseIcon
            className={
              containsRequiredAlerts || moduleLicenseExpired ? classes.requiredAlertIcon : classes.optionalAlertIcon
            }
            data={mdiAlertOctagon}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default IncompatibilityIcon;
