// je nutno importovat takto kvůli capacitoru
// eslint-disable-next-line import/no-duplicates
import "capacitor-elinkx-network";
// eslint-disable-next-line import/no-duplicates
import { ElinkxNetworkPlugin } from "capacitor-elinkx-network";
import { User } from "oidc-client";
import type { Middleware } from "redux";

import { NetworkStatus, NetworkStrength } from "@elx-element/common/enums";
import { getConfigurationStringValue } from "@elx-element/common/envconf";

import { Plugins } from "@capacitor/core";

import { SET_NETWORK_STATUS } from "./main/types";

import { setNetworkStatus } from "./main/action";

import { webStorage } from "../auth/userManager";


export const DisableRemoveOidcUserMiddleware = (store => next => action => {
  // nechceme odstranit usera s reduxu, ale potrebujeme vytvorit zmenu v reduxu
  // toto chci ale pouze v případě, že jsem v capacitoru a nebo když má uživatel PIN
  // TODO: po odladeni tato moznost pouze v capacitoru
  if (action.type === "redux-oidc/SILENT_RENEW_ERROR" || action.type === "redux-oidc/USER_EXPIRED" || action.type === "redux-oidc/USER_SIGNED_OUT") {
    const state = store.getState();
    const user = state.oidc.user as User;
    if (user) {
      webStorage.offlineAuthAvailable().then(item => {
        if (item) {
          next({
            type: "redux-oidc/USER_EXPIRED_NOT_REMOVE",
            user: { ...state.oidc, isLogOut: action.type === "redux-oidc/USER_SIGNED_OUT" }
          });
        } else {
          next(action);
        }
      }).catch(() => next(action))
      return;
    }
  }
  next(action);
  
}) as Middleware;

export const DetectNetworkStatusMiddleware = (_ /** store */ => next => {
  let setFromExternal = false;
  const network = Plugins.ElinkxNetwork as ElinkxNetworkPlugin;

  network.getStatus().then(item => {
    if (item.networkType === "browser") {
      const abort = new AbortController()
      setTimeout(() => {
        abort.abort()
      }, 5000);
      const testUrl = new URL(getConfigurationStringValue(window.env.webcontainer, "AUTH_URL"));
      testUrl.searchParams.append("s", `${new Date().getTime()}`);
      fetch(`${testUrl}`, {
        signal: abort.signal
      }).then((data) => data.json()).then(data => data.public_key).then((hasData) => {
        if (setFromExternal)
          return
        if (hasData) {
          next(setNetworkStatus(NetworkStatus.online))
        }
      }).catch(() => {
        if (setFromExternal)
          return
        next(setNetworkStatus(NetworkStatus.offline))
      })
    }
    next(setNetworkStatus(item.networkStrength !== NetworkStrength.none ? NetworkStatus.online : NetworkStatus.offline))
  });

  network.addListener("networkStatusChange", (status) => {
    setFromExternal = true;
    next(setNetworkStatus(status.networkStrength !== NetworkStrength.none ? NetworkStatus.online : NetworkStatus.offline))
  });

  return action => {
    setFromExternal = setFromExternal || action.type === SET_NETWORK_STATUS || action.type === SET_NETWORK_STATUS;
    next(action);
  }
}) as Middleware