import { useEffect, useState } from "react";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { EventCode, ScanType } from "@elx-element/common/enums";
import { dispatchScannerEvent } from "@elx-element/common/events/scanner";
import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";

import { mdiClose } from "@mdi/js";

import { setScannerLastScan } from "../../store/core/action";
import { selectScannerLastScan, selectScannerSettings, selectTexts } from "../../store/selectors";

import useStyles from "./styles";

import { scanAgain } from "./cameraScannerCore";

const CammeraScanner = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const texts = useSelector(selectTexts);
  const scannerSettings = useSelector(selectScannerSettings);
  const scannerLastScan = useSelector(selectScannerLastScan);
  const [processingPhoto, setProcessingPhoto] = useState(false);

  useEffect(() => {
    if (scannerLastScan === undefined) {
      setProcessingPhoto(false);
    }
  }, [scannerLastScan]);

  /** Zrušení skenování - zavření skeneru */
  const handleClose = () => {
    // vyvolání události zrušení skenování
    dispatchScannerEvent({ type: EventCode.scanAborted, scan: undefined });
  };

  /** Kliknutí na tlačítko "POUŽÍT" */
  const handleUseCode = () => {
    if (scannerLastScan?.value) {
      // Vyvolání eventu s naskenovanou hodnotou
      dispatchScannerEvent({ type: EventCode.scanFinished, scan: scannerLastScan });
    }
  };

  /** Při opakovaném skenování vymaže poslední sken v paměti */
  const handleScanAgain = () => {
    dispatch(setScannerLastScan(undefined));
    scanAgain(scannerSettings);
  };

  /** Provede sken bez vymazání předchozí hodnoty */
  const handleScan = () => {
    setProcessingPhoto(true);
    scanAgain(scannerSettings);
  };

  return (
    <Dialog
      fullScreen
      open={
        (scannerSettings?.active ?? false) &&
        (scannerSettings?.type === ScanType.cameraBarcode ||
          scannerSettings?.type === ScanType.cameraOcr ||
          scannerSettings?.type === ScanType.cameraQr ||
          scannerSettings?.type === ScanType.cameraPhoto)
      }
      onClose={handleClose}
      data-app={process.env.REACT_APP_NAME}
      classes={{
        paper: classes.scannerDialogPaper,
        root: classes.scannerDialogRoot,
        container: classes.scannerDialogContainer,
      }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <BaseIcon data={mdiClose} />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {scannerSettings?.type !== ScanType.cameraPhoto && (
              <>
                {texts.SCANNING} {(scannerSettings?.name ?? "").toUpperCase()}
              </>
            )}
            {scannerSettings?.type === ScanType.cameraPhoto &&
              scannerSettings?.name &&
              `${texts.TAKING_PICTURE} - ${(scannerSettings?.name ?? "").toUpperCase()}`}
            {scannerSettings?.type === ScanType.cameraPhoto && !scannerSettings?.name && `${texts.TAKING_PICTURE}`}
          </Typography>
        </Toolbar>
      </AppBar>
      <Typography variant="h6" className={classes.scannedValue}>
        {scannerSettings?.type !== ScanType.cameraPhoto && scannerLastScan?.value}
      </Typography>

      {scannerLastScan && (
        <div className={classes.scanButtonContainer}>
          <Button
            color="primary"
            variant="contained"
            className={clsx(
              classes.scanButton,
              scannerSettings?.type === ScanType.cameraPhoto ? classes.scanButtonCentered : undefined
            )}
            onClick={handleUseCode}
          >
            {texts.USE}
          </Button>
          {scannerSettings?.type !== ScanType.cameraPhoto && (
            <Button
              color="primary"
              variant="outlined"
              className={clsx(classes.scanButton, classes.scanButtonAgain)}
              onClick={handleScanAgain}
            >
              {texts.SCAN_AGAIN}
            </Button>
          )}
        </div>
      )}
      {scannerSettings?.type === ScanType.cameraPhoto && (
        <div className={classes.scanButtonContainer}>
          <Button
            color="primary"
            variant="contained"
            className={clsx(
              classes.scanButton,
              scannerSettings?.type === ScanType.cameraPhoto ? classes.scanButtonCentered : undefined
            )}
            startIcon={
              processingPhoto ? (
                <CircularProgress size={23} color="primary" sx={{ verticalAlign: "text-bottom" }} />
              ) : undefined
            }
            onClick={handleScan}
            disabled={processingPhoto}
          >
            {!processingPhoto && texts.TAKE_PICTURE}
          </Button>
        </div>
      )}
    </Dialog>
  );
};
export default CammeraScanner;
