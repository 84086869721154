/* eslint-disable class-methods-use-this */

// je nutno importovat takto kvůli capacitoru
// eslint-disable-next-line import/no-duplicates
import "capacitor-elinkx-security";
// eslint-disable-next-line import/no-duplicates
import { SecurityPlugin } from "capacitor-elinkx-security";
import { StateStore } from "oidc-client";

import { authStorageKey } from "@elx-element/common/storage";
import { parseJwt } from "@elx-element/common/utils/token";

import { Plugins } from "@capacitor/core";

/**
 * Při testování se šlo přihlásit pouze pod jedním uživatelem
 * Vycházím tedy z tohoto předpokladu
 * Slouží jako proxy, z tohot duvodu vypnuti class-method-use-this
 */
class WebStorage implements StateStore {


    async biometricsAvailable() {
        const security = Plugins.Security as SecurityPlugin;
        return security.unlockType().then(value => value.value === "biometric");
    }

    
    async offlineAuthAvailable(): Promise<boolean> {
        try {
            if (window.inCapacitor) {
                const security = Plugins.Security as SecurityPlugin;
                return security.unlockType().then(value => ["biometric", "pin"].indexOf(value.value) > -1);
            }
        }
        catch (e) {
            console.debug(e);
        }
        return false;
    }


    async unlock(opts: { pin?: string, title?: string, desc?: string }) {
        const security = Plugins.Security as SecurityPlugin;
        return security.unlock(opts);
    }


    async set(key: string, value: string) {
        sessionStorage.setItem(key, value);
        if (key === authStorageKey && window.inCapacitor) {
            const security = Plugins.Security as SecurityPlugin;
            const parsed = JSON.parse(value);
            const token = parseJwt(parsed.access_token);
            security.setAuth({ authData: value, pin: token?.properties?.OfflinePin });
        }
    }

    async get(key: string) {
        const item = sessionStorage.getItem(key);
        if (key === authStorageKey && window.inCapacitor) {
            const security = Plugins.Security as SecurityPlugin;
            if (!item) {
                try {
                    const savedItem = await security.getAuth();
                    if (savedItem.value) {
                        sessionStorage.setItem(key, savedItem.value);
                        return savedItem.value;
                    }
                } catch (e) { 
                    console.debug(e);
                }
            } else if (!(await security.unlocked()).value) {
                const parsed = JSON.parse(item);
                const token = parseJwt(parsed.access_token);
                security.setAuth({ authData: item, pin: token?.properties?.OfflinePin });
            }
        }
        return item;
    }

    removeAuthSync() {
        sessionStorage.removeItem(authStorageKey);
    }

    async removeAuth(): Promise<string | null> {
        const security = Plugins.Security as SecurityPlugin;
        security.setAuth();
        return Promise.resolve(authStorageKey);
    }

    async removeAll(): Promise<string[]> {
        const security = Plugins.Security as SecurityPlugin;
        security.setAuth()
        return this.getAllKeys().then(keys => keys.map(key => {
            sessionStorage.removeItem(key)
            return key
        }));

    }

    async remove(key: string) {
        const item = sessionStorage.getItem(key);
        if (key === authStorageKey) {
            const security = Plugins.Security as SecurityPlugin;
            security.setAuth();
        }
        sessionStorage.removeItem(key)
        return Promise.resolve(item);
    }

    getAllKeys() {
        const keys: string[] = [];

        for (let index = 0; index < sessionStorage.length; index++) {
            const key = sessionStorage.key(index);
            if (key)
                keys.push(key)
        }
        return Promise.resolve(keys);
    }
}



export default WebStorage;