/* eslint-disable max-classes-per-file */
import { differenceInDays, toDate } from "date-fns";

import { getConfigurationNumberValue } from "@elx-element/common/envconf";

import { WidgetModel } from "./types.generated";

import { ModuleInterfaceDirectionFlag } from "./enums";

/**
 * Model pro texty modulů, které se zobrazují v menu a dlaždicích.
 */
export class LocalizedName {
  /// Lokalizace
  culture: string;

  // Text
  text: string;

  constructor(culture: string, text: string) {
    this.culture = culture;
    this.text = text;
  }
}

/**
 * ModuleInterfaceEvent
 */
export interface ModuleInterfaceEvent {
  /**
   * Identifikátor modulu
   */
  moduleId: string;
  /**
   * Identifikátor události
   */
  eventName: string;
  /**
   * Definice objektu k validaci
   */
  objectDefinition: Array<{ name: string; type: string }> | undefined;
  /**
   * Definuje směr komunikace - in, nebo out
   */
  directionFlag: ModuleInterfaceDirectionFlag;
  /**
   * Pokud je required pak aplikace vyžaduje pro svůj běh nainstalován další modul splňující toto pravidlo
   */
  required?: boolean;
  /**
   * Příznak potvrzení, pokud není nalezena druhá strana komunikace, rozhraní není potvrzeno.
   */
  confirmed?: boolean;
}

/**
 * Datový model pro interface modulu.
 */
export interface ModuleInterface {
  // Definuje in a out komunikační vazby na ostatní moduly.
  events: ModuleInterfaceEvent[];
  // Závislost na konkrétní verzi modulů
  dependencies: Record<string, string>[];
  // Parametr definuje zda je modul připraven být offline
  availableOfflineMode: boolean;
  // Příznaky aktivace dalších služeb
  services: Record<string, boolean>;
}

/**
 * Model zaregistrovaného modulu aplikace.
 */
export class RegisteredModule {
  /** Používá se jako identifikátor modulu. Musí být unikátní. Stejně musí být pojmenován i adresář modulu. */
  moduleId: string;

  /** Routa aplikace */
  route: string;

  /** Oprávnění pro přístup k aplikaci - textový řetězec poskytovaný keycloak serverem */
  requiredPermission?: string;

  /** Textová definice ikony z kolekce Material UI Icons */
  tileIcon?: string;

  /** Barva dlaždice  */
  tileColor?: string;

  /** Texty dlaždice */
  localizedNames: Array<LocalizedName>;

  /** Definice rozhraní pro komunikaci s okolím */
  interface?: ModuleInterface; // seznam registrovaných metod

  /** Příznak značí, jestli se jedná o externí nebo lokální modul */
  externalModule: boolean;

  /** Povolení zobrazení v mobilní aplikaci */
  allowOnMobile: boolean = true;

  /** příznak dostupnosti konfiguračního souboru */
  configExists: boolean = false;

  /** Vráti text dlaždice ve vybraném jazyce */
  getLocalizedName: (culture: string) => string;

  /** Kolekce widgetů, kterýmí modul disponuje. Pokud je kolekce prázdná, modul nemá widget. */
  widgets: WidgetModel[] | undefined;

  /** Datum a čas expirace licence pro daný modul. */
  licenseExpiration: Date | undefined;

  /** Typ licence (Full, Readonly, ...) */
  licenseType: string | undefined;

  /** Verze modulu - hodnota je dynamicky načtena ze souboru interface.json po startu aplikace */
  version: string | undefined;

  /** Číslo sestavení - hodnota je dynamicky načtena ze souboru interface.json po startu aplikace */
  buildNumber: string | undefined;

  /** Vrací true pokud licence expiruje do X dnů */
  licenseExpiring = (): boolean => {
    if (this.licenseExpiration) {
      const daysDiff = differenceInDays(this.licenseExpiration!, new Date());
      const expiringDays = getConfigurationNumberValue(window.env.webcontainer, "MODULE_LICENSE_EXPIRING_DAYS");
      return daysDiff <= expiringDays && daysDiff >= 0;
    }
    return false;
  };

  /** Vrací true pokud licence již expirovala */
  licenseExpired = () => (this.licenseExpiration ? differenceInDays(this.licenseExpiration!, new Date()) < 0 : false);

  /** Hodnota pro řazení v hlavním menu */
  order: number | undefined;

  constructor(
    moduleId: string,
    route: string,
    localizedNames: Array<LocalizedName>,
    externalModule: boolean,
    allowOnMobile: boolean,
    requiredPermission?: string,
    tileIcon?: string,
    tileColor?: string,
    widgets?: Array<WidgetModel>,
    licenseExpiration?: string,
    licenseType?: string,
    order?: number
  ) {
    this.moduleId = moduleId;
    this.route = route;
    this.requiredPermission = requiredPermission;
    this.tileIcon = tileIcon;
    this.tileColor = tileColor;
    this.localizedNames = localizedNames;
    this.externalModule = externalModule;
    this.allowOnMobile = allowOnMobile;
    this.widgets = widgets;
    this.licenseType = licenseType;
    this.licenseExpiration = licenseExpiration ? toDate(Date.parse(licenseExpiration)) : undefined;
    this.order = order;
    this.getLocalizedName = (culture: string) => this.localizedNames.find(x => x.culture === culture)?.text as string;
  }
}

/**
 * RouteModel
 * Definuje routu aplikace
 */
export interface RouteModel {
  /** Routa */
  route: string;
  /** Komponenta */
  component: () => JSX.Element;
  /** Prívátní / Public */
  private: boolean;
  /** Označení defaultní routy - použije se pokud je v URL pouze lomítko */
  default: boolean;
}

/** The notification model */
export interface INewMessageModel {
  /** Odesílatel */
  sender: string | undefined;
  /** Odesílatel */
  recipient: string | undefined;
  /** The notification subject */
  action: string | undefined;
  /** The notification text */
  title: string | undefined;
  /** The notification message type */
  description: string | undefined;
  /** The notification read flag */
  read: boolean;
  /** Notification action processed */
  processed: boolean;
  severity: number;
}

/** MessageEchoModel */
export interface MessageEchoModel {
  /** Id zprávy */
  messageId: number;
  /** Typ zprávy */
  messageType: string;
}

export interface CustomModuleGridData {
  widget?: WidgetModel | undefined;
  layout: ReactGridLayout.Layout;
}
