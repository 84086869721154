import { storePlatform } from "@elx-element/common/storage";

import {
  CLEAR_NFC_WRITER,
  CLEAR_SCANNER,
  CONFIGURE_NFC_WRITER,
  CONFIGURE_SCANNER,
  CONFIRM_MODULE_INTERFACE_EVENT,
  coreActionTypes,
  coreState,
  SET_ACTIVE_MODULE,
  SET_DASHBOARD_SETTINGS,
  SET_MAIN_MENU_OPEN,
  SET_MODULE_CONFIG_EXISTS,
  SET_MODULE_INTERFACE,
  SET_MODULE_UPDATED,
  SET_OPEN_ERROR_DETAIL_REFERENCE,
  SET_PLATFORM,
  SET_PLATFORM_IS_ACTIVE,
  SET_REGISTERED_MODULES,
  SET_REGISTERED_MODULES_LOADED,
  SET_SCANNER_LAST_SCAN,
  SET_WEBCONTAINER_UPDATED,
} from "./types";

const initialState: coreState = {
  mainMenuOpen: false,
  registeredModules: [],
  registeredModulesLoaded: false,
  activeModuleId: undefined,
  platform: undefined,
  platformIsActive: true,
  scannerSettings: undefined,
  scannerLastScan: undefined,
  nfcWriterSettings: undefined,
  dashboardSettings: [],
  openErrorDetailReference: undefined,
  webContainerUpdated: undefined,
  moduleUpdated: undefined,
};

export default function coreReducer(state = initialState, action: coreActionTypes): coreState {
  const modules = state.registeredModules;

  switch (action.type) {
    case SET_MAIN_MENU_OPEN:
      return {
        ...state,
        mainMenuOpen: action.payload,
      };
    case SET_ACTIVE_MODULE:
      return {
        ...state,
        activeModuleId: action.payload,
      };
    case SET_REGISTERED_MODULES:
      return {
        ...state,
        registeredModules: action.payload,
      };
    case SET_REGISTERED_MODULES_LOADED:
      return {
        ...state,
        registeredModulesLoaded: true,
      };
    case SET_MODULE_INTERFACE:
      return {
        ...state,
        registeredModules: state.registeredModules.map(module =>
          module.moduleId.toLocaleLowerCase() === action.payload.moduleId.toLocaleLowerCase()
            ? {
                ...module,
                interface: action.payload.interface,
                version: action.payload.version,
                buildNumber: action.payload.buildNumber,
              }
            : module
        ),
      };
    case CONFIRM_MODULE_INTERFACE_EVENT:
      modules.forEach(module => {
        module.interface?.events?.forEach(event => {
          if (
            !event.confirmed &&
            event.moduleId === action.payload.moduleId &&
            event.eventName === action.payload.event.eventName &&
            event.directionFlag !== action.payload.event.directionFlag
          ) {
            // eslint-disable-next-line no-param-reassign
            event.confirmed = true;
          }
        });
      });
      return {
        ...state,
        registeredModules: modules,
      };
    case SET_PLATFORM:
      storePlatform(action.payload);
      return {
        ...state,
        platform: action.payload,
      };
    case SET_PLATFORM_IS_ACTIVE:
      return {
        ...state,
        platformIsActive: action.payload,
      };
    case SET_MODULE_CONFIG_EXISTS:
      return {
        ...state,
        registeredModules: state.registeredModules.map(module =>
          module.moduleId.toLocaleLowerCase() === action.payload.toLocaleLowerCase()
            ? { ...module, configExists: true }
            : module
        ),
      };
    case CONFIGURE_SCANNER:
      return {
        ...state,
        scannerSettings: action.payload,
      };
    case CLEAR_SCANNER:
      return {
        ...state,
        scannerSettings: undefined,
        scannerLastScan: undefined,
      };
    case SET_SCANNER_LAST_SCAN:
      return {
        ...state,
        scannerLastScan: action.payload,
      };
    case CONFIGURE_NFC_WRITER:
      return {
        ...state,
        nfcWriterSettings: action.payload,
      };
    case CLEAR_NFC_WRITER:
      return {
        ...state,
        nfcWriterSettings: undefined,
      };
    case SET_DASHBOARD_SETTINGS:
      return {
        ...state,
        dashboardSettings: action.payload,
      };
    case SET_OPEN_ERROR_DETAIL_REFERENCE:
      return {
        ...state,
        openErrorDetailReference: action.payload,
      };
    case SET_WEBCONTAINER_UPDATED:
      return {
        ...state,
        webContainerUpdated: action.payload,
      };
    case SET_MODULE_UPDATED:
      return {
        ...state,
        moduleUpdated: action.payload,
      };
    default:
      return state;
  }
}
