import React, { useEffect } from "react";

import { useSelector } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";

import { getConfigurationNumberValue } from "@elx-element/common/envconf";

import { selectPlatform, selectTexts, selectUser } from "../../store/selectors";

import useAppStyles from "../app/styles";

import * as CoreUser from "../user/core";

const LogoutPage = () => {
  const { classes } = useAppStyles();
  const platform = useSelector(selectPlatform);
  const texts = useSelector(selectTexts);
  const user = useSelector(selectUser);

  // Timeout komponenty. Pokud loading probíhá déle než stanovaný čas, zobrazí se chyba.
  const [timeoutOccured, setTimeoutOccured] = React.useState<boolean>(false);
  let timer: any;
  const timeout = getConfigurationNumberValue(window.env.webcontainer, "KEYCLOAK_CALLBACK_TIMEOUT");

  useEffect(() => {
    timer = setTimeout(() => setTimeoutOccured(true), timeout);
    CoreUser.onLogout(platform!, user!);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={classes.centeredContent}>
      {!timeoutOccured && <CircularProgress />}
      {timeoutOccured && (
        <div>
          <div>{texts.LOGOUT_ERROR}</div>
        </div>
      )}
    </div>
  );
};

export default LogoutPage;
