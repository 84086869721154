import React, { useEffect } from "react";

import { useSnackbar } from "notistack";
import { CallbackComponent } from "redux-oidc";

import CircularProgress from "@mui/material/CircularProgress";

import { getConfigurationNumberValue } from "@elx-element/common/envconf";

import useAppStyles from "../app/styles";

import TryLaterPage from "./TryLaterPage";

import userManager from "../../auth/userManager";
import History from "../../history";

const CallbackPage = () => {
  const { classes } = useAppStyles();
  const [error, setError] = React.useState<boolean>(false);
  const snackbar = useSnackbar();

  // Timeout komponenty. Pokud loading probíhá déle než stanovaný čas, zobrazí se chyba.
  const [timeoutOccured, setTimeoutOccured] = React.useState<boolean>(false);
  let timer: any;
  const timeout = getConfigurationNumberValue(window.env.webcontainer, "KEYCLOAK_CALLBACK_TIMEOUT");

  const handleCallbackTimeout = () => {
    setTimeoutOccured(true);
    snackbar.closeSnackbar(); // vymaže zásobník chybových notifikací.
  };

  useEffect(() => {
    timer = setTimeout(handleCallbackTimeout, timeout);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const successCallback = () => {
    const url = sessionStorage.getItem("urlBeforeRedirect");
    sessionStorage.removeItem("urlBeforeRedirect");
    if (!url) {
      History.push("/dashboard");
    } else {
      History.push(`${url}`);
    }
  };

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={successCallback}
      errorCallback={e => {
        setError(true);
        throw e;
      }}
    >
      <div className={classes.centeredContent}>
        {!error && !timeoutOccured && <CircularProgress />}
        {(error || timeoutOccured) && <TryLaterPage />}
      </div>
    </CallbackComponent>
  );
};

export default CallbackPage;
