

import { WebContainerPlatform } from "@elx-element/common/enums";

import { AppState } from ".";

/** MainApp selectory */
export const selectTexts = (state: AppState) => state.main.texts;
export const selectCulture = (state: AppState) => state.main.culture;
export const selectCultures = (state: AppState) => state.main.cultures;
export const selectRoutes = (state: AppState) => state.main.routes;
export const selectNetworkStatus = (state: AppState) => state.main.networkStatus;
export const selectMachineSettings = (state: AppState) => state.main.machineSettings;

/** core */
export const selectActiveModule = (state: AppState) =>
  state.core.registeredModules.find(x => x.moduleId === state.core.activeModuleId);
export const selectRegisteredModules = (state: AppState) => state.core.registeredModules;
export const selectRegisteredModulesLoaded = (state: AppState) => state.core.registeredModulesLoaded;
export const selectPlatform = (state: AppState) => state.core.platform;
export const isMobilePlatform = (state: AppState) => state.core.platform !== WebContainerPlatform.web;
export const selectDashboardSettings = (state: AppState) => state.core.dashboardSettings;
export const selectOpenErrorDetailReference = (state: AppState) => state.core.openErrorDetailReference;
export const selectModuleUpdated = (state: AppState) => state.core.moduleUpdated;
export const selectWebContainerUpdated = (state: AppState) => state.core.webContainerUpdated;

/** scanner */
export const selectScannerSettings = (state: AppState) => state.core.scannerSettings;
export const selectScannerLastScan = (state: AppState) => state.core.scannerLastScan;

/** nfc writer */
export const selectNfcWriterSettings = (state: AppState) => state.core.nfcWriterSettings;

/** message */
export const selectMessages = (state: AppState) => state.message.messages;
export const selectMessagesCount = (state: AppState) => state.message.messages.length;
export const selectNotifications = (state: AppState) => state.message.notifications;
export const selectNotificationsCount = (state: AppState) => state.message.notifications.length;
export const selectApplicationEvents = (state: AppState) => state.message.applicationEvents;
export const selectSubcriptions = (state: AppState) => state.message.subcriptions;
export const selectUsers = (state: AppState) => state.message.users;

/** oidc */
export const selectUserExpired = (state: AppState) =>
  state.oidc.isLogOut || (state.oidc.user?.expires_at ?? 0) < Math.floor(new Date().getTime() / 1000);
export const selectUserAuthorized = (state: AppState) => !!state.oidc.user; // user je autorizovan (sic token vyprsel)
export const selectToken = (state: AppState): string | undefined => state.oidc.user?.access_token ?? "";
export const selectUser = (state: AppState) => state.oidc.user;
export const selectOfflineBaseUrl = (state: AppState) => (window.inCapacitor ? state.main.baseOfflineUrl : null);
export const selectOfflineMode = (state: AppState) => !!(window.inCapacitor && state.main.baseOfflineUrl);
