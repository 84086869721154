import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import ReduxLogger from "redux-logger";
import { loadUser, reducer as oidcReducer, UserState } from "redux-oidc";
import thunkMiddleware from "redux-thunk";

import { getConfigurationBoolValue } from "@elx-element/common/envconf";

import coreReducer from "./core/reducer";
import mainReducer from "./main/reducer";
import messageReducer from "./message/reducer";
import { DetectNetworkStatusMiddleware, DisableRemoveOidcUserMiddleware } from "./middleware";

import userManager from "../auth/userManager";

const rootReducer = combineReducers({
  core: coreReducer,
  main: mainReducer,
  message: messageReducer,
  oidc: (state: UserState | undefined, action): UserState =>
    action.type === "redux-oidc/USER_EXPIRED_NOT_REMOVE"
      ? ({ ...state, ...action.user } as UserState)
      : oidcReducer(state, action),
});

export type AppState = ReturnType<typeof rootReducer>;
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [
  thunkMiddleware,
  DisableRemoveOidcUserMiddleware,
  DetectNetworkStatusMiddleware,
];
if (getConfigurationBoolValue(window.env.webcontainer, "ENABLE_REDUX_DEBUG")) {
  middlewares.push(ReduxLogger);
}
const middleWareEnhancer = composeEnhancers(applyMiddleware(...middlewares));
const store = createStore(rootReducer, middleWareEnhancer);
loadUser(store, userManager);
export default store;
