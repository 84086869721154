/* eslint-disable simple-import-sort/imports */
import { Dispatch } from "react";

import { App as CapApp } from "@capacitor/app";

import { User } from "oidc-client";
import { Notification, WebContainerPlatform } from "@elx-element/common/enums";
import { NotificationEventModel } from "@elx-element/common/events/types";
import { getConfigurationBoolValue, getConfigurationStringValue } from "@elx-element/common/envconf";
import { apiMan } from "@elx-element/common/apiClient/apiMan";
import { dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import * as MessageActionTypes from "../../store/message/types";
import { NotificationModel, NotificationSubcriptionModel, WebContainerClient } from "../../types.generated";

import { removeNotificationByCode } from "../../store/message/action";

import userManager, { webStorage } from "../../auth/userManager";
import History from "../../history";
import Lang from "../../languages/lang";
import { loadSubcriptions } from "../app/core";

const oidcDebug = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_OIDC_DEBUG");

/**
 * Odhlášení odběru konkrétního typu notifikace pomocí notificationId. (Nemusí být v subscripcích.)
 * Po odhlášení jsou všechny výskyty daného typu odebrány ze zásobníku notifikací.
 * @param notificationId
 * @param client
 * @param dispatch
 * @param successText
 */
export const handleUnsubscribeNotificationId = async (
  notification: NotificationModel,
  client: WebContainerClient,
  dispatch: Dispatch<MessageActionTypes.addSubcriptions | MessageActionTypes.removeNotificationByCode>,
  sTexts: Lang
) => {
  let success = true;
  try {
    await apiMan(client.eventSubscription2(notification.id));
    await loadSubcriptions(client, dispatch);
    await dispatch(removeNotificationByCode(notification.code!));
  } catch {
    success = false;
  }
  if (success) {
    dispatchNotificationEvent(
      new NotificationEventModel(sTexts.UNSUBSCRIBE_SUCCESS.replace("{0}", notification.code!), Notification.success)
    );
  }
};

/**
 * Odhlášení odběru konkrétního typu notifikace pomocí notificationCode.
 * @param notificationId
 * @param client
 * @param subcriptions
 * @param successText
 */
export const handleUnsubscribeNotificationCode = async (
  notificationCode: string | undefined,
  subcriptions: Array<NotificationSubcriptionModel>,
  client: WebContainerClient,
  dispatch: Dispatch<MessageActionTypes.addSubcriptions>,
  successText: string
) => {
  if (notificationCode) {
    let success = true;
    try {
      const subscription = subcriptions.find(x => x.eventCodeGroup === notificationCode);
      if (subscription) {
        await apiMan(client.eventSubscription3(subscription.id));
        await loadSubcriptions(client, dispatch);
      }
    } catch {
      success = false;
    }
    if (success) {
      dispatchNotificationEvent(
        new NotificationEventModel(successText.replace("{0}", notificationCode), Notification.success)
      );
    }
  } else {
    throw new Error("handleUnsubscribeNotification: NotificationCode cannot be undefined.");
  }
};

/**
 * Přihlášení odběru konkrétního typu notifikace pomocí notificationCode.
 * @param notificationId
 * @param client
 * @param successText
 */
export const handleSubscribeNotificationCode = async (
  notificationCode: string | undefined,
  client: WebContainerClient,
  dispatch: Dispatch<MessageActionTypes.addSubcriptions>,
  successText: string
) => {
  if (notificationCode) {
    let success = true;
    try {
      await apiMan(client.eventSubscription(notificationCode));
      await loadSubcriptions(client, dispatch);
    } catch {
      success = false;
    }
    if (success) {
      dispatchNotificationEvent(
        new NotificationEventModel(successText.replace("{0}", notificationCode), Notification.success)
      );
    }
  } else {
    throw new Error("handleSubscribeNotification: NotificationCode cannot be undefined.");
  }
};

/** onLogin
 * @param platform
 */
export const onLogin = async (platform: WebContainerPlatform) => {
  if (platform !== WebContainerPlatform.web) {
    // přihlášení pomocí cordova.InAppBrowser
    userManager
      .signinPopup({
        // eslint-disable-next-line camelcase
        popup_redirect_uri: `${getConfigurationStringValue(window.env.webcontainer, "MOBILE_RETURN_URI")}callback`,
      })
      .catch(error => {
        if (oidcDebug) {
          console.debug(`signin popup error:${error}`);
        }
      })
      .then(_ => {
        // redirect na dashboard po přihlášení
        History.push("/dashboard");
      });
  } else {
    userManager.signinRedirect();
  }
};

/** onLogout
 * @param platform
 */
export const onLogout = async (platform: WebContainerPlatform, user: User) => {
  /// při odhlášení se provede výmaz sessionStorage.
  await webStorage.removeAll();
  if (platform !== WebContainerPlatform.web) {
    // odhlášení pomocí cordova.InAppBrowser
    userManager
      .signoutPopup({
        // eslint-disable-next-line camelcase
        id_token_hint: user.id_token,
        // eslint-disable-next-line camelcase
        post_logout_redirect_uri: `${getConfigurationStringValue(window.env.webcontainer, "MOBILE_RETURN_URI")}close`,
      })
      .catch(error => {
        if (oidcDebug) {
          console.debug(`signout popup error:${error}`);
        }
      })
      .then(_ => {
        // uzavření aplikace po odhlášeení
        CapApp.exitApp();
      });
  } else {
    userManager.signoutRedirect({
      // eslint-disable-next-line camelcase
      id_token_hint: user.id_token,
    });
  }
};
