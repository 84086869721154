import { Dispatch } from "react";

import { ScanType } from "@elx-element/common/enums";
import { ScannerControl } from "@elx-element/common/events/types";

import * as CoreStoreTypes from "../../store/core/types";

import { handleCameraScannerControlEvent } from "./cameraScannerCore";
import { handleNfcScannerControlEvent } from "./nfcCore";
import { handleZebraScannerControlEvent } from "./zebraScannerCore";

/**
 * Listener změny state s nastavením skeneru
 * @param settings - nastavení skeneru ScannerControl
 * @param dispatch
 */
export const handleScannerControlEvent = (
  scannerNewSettings: CustomEventInit<ScannerControl>,
  dispatch: Dispatch<CoreStoreTypes.configureScanner | CoreStoreTypes.clearScanner | CoreStoreTypes.setScannerLastScan>,
  scannerActualSettings: ScannerControl | undefined
) => {
  // Příchozí prametry
  // settings.detail!.active (boolean)
  // settings.detail!.name   (string | undefined)
  // settings.detail!.regex  (string | undefined)
  // settings.detail!.type   (Enums.ScanType)

  if (scannerActualSettings === scannerNewSettings) {
    return;
  }

  if (
    scannerNewSettings.detail!.active === false &&
    scannerNewSettings.detail!.type === undefined &&
    scannerActualSettings
  ) {
    // eslint-disable-next-line no-param-reassign
    scannerNewSettings.detail!.type = scannerActualSettings!.type;
  }

  switch (scannerNewSettings.detail!.type) {
    case ScanType.zebraBarcode:
      handleZebraScannerControlEvent(scannerNewSettings, dispatch);
      break;
    case ScanType.nfcMessage:
      handleNfcScannerControlEvent(scannerNewSettings, dispatch);
      break;
    default:
      handleCameraScannerControlEvent(scannerNewSettings, dispatch);
      break;
  }
};

/**
 * Všechny komponenty na stránce musejí být zneviditelněny, protože komponenta fotoaparátu se renderuje pod stránku s aplikací.
 */
export const hideScannerPageBackground = () => {
  document.getElementById(`${process.env.REACT_APP_NAME}_root`)!.classList.add("hidden");
  document.querySelectorAll("[role='presentation']").forEach(e => {
    if (e.getAttribute("data-app") !== process.env.REACT_APP_NAME) {
      e.classList.add("hidden");
    }
  });
  document.body.classList.add("transparent");
};

/**
 * Po zavření scanneru opět zviditelníme všechny části aplikace.
 */
export const showScannerPageBackground = () => {
  document.getElementById(`${process.env.REACT_APP_NAME}_root`)!.classList.remove("hidden");
  document.querySelectorAll("[role='presentation']").forEach(e => {
    e.classList.remove("hidden");
  });
  document.body.classList.remove("transparent");
};
