/* eslint-disable import/no-extraneous-dependencies */
import { createBrowserHistory, Location } from "history";

import { getConfigurationBoolValue } from "@elx-element/common/envconf";
import { dispatchEvent } from "@elx-element/common/events/dispatchers";

import store from "./store";

const history = createBrowserHistory();

const dispatchUnloadModule = (location: Location<unknown>) => {
  const openModuleId = store.getState().core.activeModuleId; // reference na otevřený modul
  if (shouldModuleBeClosed(openModuleId, location.pathname)) {
    // unmout modulu, musí být proveden stejnou instancí reactu jako render.
    dispatchEvent(
      `${openModuleId!.toUpperCase()}:UNLOAD`,
      null,
      getConfigurationBoolValue(window.env.webcontainer, "ENABLE_EVENT_DEBUG")
    );
  }
};

/**
 * Rozpoznání jestli má dojít k unmountu modulu dle změny v location.
 * @param openModuleId Id aktuálně otevřeného modulu
 * @param newLocation nová url
 * @returns vrací true pokud má dojít k unmountu modulu.
 */
const shouldModuleBeClosed = (openModuleId: string | undefined, newLocation: string) => {
  const newLocationCleared = newLocation.substring(1, newLocation.length);
  const secondSlashPosition = newLocationCleared.indexOf("/");
  return (
    openModuleId &&
    openModuleId !==
      newLocationCleared.substring(0, secondSlashPosition > -1 ? secondSlashPosition : newLocationCleared.length)
  );
};

/**
 * Před samotným přeroutováním je nutné bezpečně odpojit namountovaný modul.
 * Zde se vyšle událost, na základě které cílový modul spustí čistící rutinu.
 */
history.listen(dispatchUnloadModule);

// uložení objektu s historií prohlížeče pro distribuci modulům, tyto pak mohou pushovat url přímo do routru webcontaineru
window.env.webcontainer.history = history;
export default history;
