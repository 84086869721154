import React, { useEffect } from "react";

import { useSelector } from "react-redux";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";

import { mdiDotsVertical } from "@mdi/js";

import { DashboardSettingsModel } from "../../types.generated";

import { CustomModuleGridData, RegisteredModule } from "../../models";

import { selectTexts } from "../../store/selectors";

import useStyles from "./styles";

interface WidgetSelectorProps {
  className?: string;
  module: RegisteredModule;
  customModuleGridData: CustomModuleGridData;
  onWidgetChange: (settings: DashboardSettingsModel) => void;
}

/**
 * Komponenta pro výběr widgetu.
 * @param props
 * @returns
 */
const WidgetSelector = (props: WidgetSelectorProps) => {
  const texts = useSelector(selectTexts);
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  useEffect(() => {
    setOpen(Boolean(anchorEl));
  }, [anchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setSelectedValue = (value: number | undefined) => {
    const settings: DashboardSettingsModel = {
      moduleId: props.module.moduleId,
      widgetId: value,
      positionX: props.customModuleGridData.layout.x,
      positionY: props.customModuleGridData.layout.y,
    };
    props.onWidgetChange(settings);
    handleClose();
  };

  return (
    <div className={props.className}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        className={classes.widgetSelectorIcon}
      >
        <BaseIcon data={mdiDotsVertical} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl === null ? false : open}
        onClose={handleClose}
      >
        <MenuItem
          key={0}
          selected={props.customModuleGridData.widget === undefined}
          onClick={() => setSelectedValue(undefined)}
        >
          {texts.TILE}
        </MenuItem>
        {props.module.widgets?.map(option => (
          <MenuItem
            key={option.id}
            selected={option.id === props.customModuleGridData.widget?.id}
            onClick={() => setSelectedValue(option.id)}
          >
            {option.widgetName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default WidgetSelector;
