import React from "react";

import { useDispatch, useSelector } from "react-redux";

import {
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    Switch,
    Typography,
} from "@mui/material";

import BaseTextField from "@elx-element/ui/Inputs/BaseTextField";
import BaseDrawer from "@elx-element/ui/Surfaces/BaseDrawer";

import { setMachineSettings } from "../../store/main/action";
import {
    selectCulture,
    selectMachineSettings,
    selectTexts,
} from "../../store/selectors";

import useStyles from "./styles";

import Theme from "../../theme";

interface Props {
    open: boolean;
    dashboardControl: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
    onClose: () => void;
}

const MachineSettings = ({ open, dashboardControl, onClose }: Props) => {
    const dispatch = useDispatch();
    const [isDashboardDragable, setDashboardDragable] = dashboardControl;
    const texts = useSelector(selectTexts);
    const culture = useSelector(selectCulture);
    const machineSettings = useSelector(selectMachineSettings);
    const { classes } = useStyles();

    const setIncompatibilityAlertsEnabled = (checked: boolean) => {
        dispatch(setMachineSettings({ ...machineSettings, incompatibilityAlertsEnabled: checked }));
    };

    const setMachineName = (name: string) => {
        dispatch(setMachineSettings({ ...machineSettings, machineName: name }));
    }

    return (

        <BaseDrawer
            open={open}
            onClose={onClose}
            header={
                <>
                    <Typography variant="h2">{texts.SETTINGS}</Typography>
                    <Typography variant="body2">{texts.WEBCONTAINER_SETTINGS}</Typography>
                </>
            }
            texts={texts}
        >

            <Grid container marginTop={0}>
                <Grid item xs={12}>
                    <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">{texts.TILE_SETTINGS}</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                className={classes.formControlLabel}
                                control={
                                    <Switch
                                        checked={isDashboardDragable}
                                        onChange={(_e, checked) => setDashboardDragable(checked)}
                                        title={texts.TILE_REARRANGE}
                                    />
                                }
                                label={<>{texts.TILE_REARRANGE}
                                    <FormHelperText>{texts.TILE_REARRANGE_INFO}</FormHelperText>
                                </>}
                            />


                            <FormControlLabel
                                className={classes.formControlLabel}
                                control={
                                    <Switch
                                        checked={machineSettings.incompatibilityAlertsEnabled}
                                        onChange={(e, checked) => setIncompatibilityAlertsEnabled(checked)}
                                        title={texts.TILE_UNCOMPATIBILITY}
                                    />
                                }
                                label={<>{texts.TILE_UNCOMPATIBILITY}
                                    <FormHelperText >{texts.TILE_UNCOMPATIBILITY_INFO}</FormHelperText>
                                </>}
                            />

                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">{texts.LOCAL_SETTINGS}</FormLabel>
                        <FormGroup>
                            <Grid container>
                                <Grid item xs={12} marginTop={Theme.spacing(1.5)}>
                                    <BaseTextField
                                        culture={culture}
                                        disableStaticLabel
                                        label={texts.MACHINE_NAME}
                                        placeholder={texts.MACHINE_PLACEHOLDER}
                                        InputProps={{ inputProps: { maxLength: 100 } }}
                                        defaultValue={machineSettings.machineName ?? ""}
                                        onChange={(e) => setMachineName(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </BaseDrawer>
    );
};

export default MachineSettings;
