import { storeUsers } from "@elx-element/common/storage";

import {
  ADD_APPLICATION_EVENTS,
  ADD_MESSAGE,
  ADD_MESSAGES,
  ADD_NOTIFICATION,
  ADD_NOTIFICATIONS,
  ADD_SUBCRIPTIONS,
  ADD_USERS,
  messageActionTypes,
  messageState,
  REMOVE_MESSAGE,
  REMOVE_NOTIFICATION,
  REMOVE_NOTIFICATION_BY_CODE,
} from "./types";

const initialState: messageState = {
  notifications: [],
  messages: [],
  users: [],
  applicationEvents: [],
  subcriptions: [],
};

export default function messageReducer(state = initialState, action: messageActionTypes): messageState {
  switch (action.type) {
    case ADD_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
    case ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case REMOVE_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter(x => x.id !== action.payload),
      };
    case ADD_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(x => x.id !== action.payload),
      };
    case REMOVE_NOTIFICATION_BY_CODE:
      return {
        ...state,
        notifications: state.notifications.filter(x => x.code !== action.payload),
      };
    case ADD_USERS:
      storeUsers(action.payload);
      return {
        ...state,
        users: action.payload,
      };
    case ADD_APPLICATION_EVENTS:
      return {
        ...state,
        applicationEvents: action.payload,
      };
    case ADD_SUBCRIPTIONS:
      return {
        ...state,
        subcriptions: action.payload,
      };
    default:
      return state;
  }
}
