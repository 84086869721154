import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  content: {
    overflow: "auto",
    overflowX: "hidden",
    marginTop: 50,
    width: "100%",
  },
  centeredContent: {
    textAlign: "center",
    margin: "5rem auto",
  },
  centeredContentOffline: {
    margin: "5rem auto",
    textAlign: "center",
    position: "absolute",
    width: "100%",
  },
  copyrightBox: {
    position: "fixed",
    bottom: "0",
    right: theme.spacing(1),
    padding: 0,
    "& p": {
      fontSize: "small",
    },
  },
  errorNotification: {
    "& #notistack-snackbar svg": {
      display: "none",
    },
    "& .message": {
      fontSize: "smaller",
      marginTop: theme.spacing(0.5),
      width: 250,
      wordBreak: "break-word",
      maxHeight: 200,
      overflow: "hidden"
    },
  },
  notificationDetail: {
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    width: 329,
    wordBreak: "break-word",
    "& .description": {
      fontSize: "smaller",
    },
  },
  offlineNotificationBox: {
    height: 24,
    lineHeight: "24px",
    paddingLeft: theme.spacing(1),
    width: "100%",
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    fontSize: 12,
    fontWeight: "bold",
    "& svg": {
      float: "left",
      display: "inline-block",
      marginTop: theme.spacing(0.5),
      fontSize: 16,
      color: theme.palette.warning.contrastText,
      marginRight: theme.spacing(0.5),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export default useStyles;
