import {
  ApplicationEventModel,
  MessageModel,
  NotificationModel,
  NotificationSubcriptionModel,
  UserModel,
} from "../../types.generated";

export interface messageState {
  notifications: Array<NotificationModel>;
  messages: Array<MessageModel>;
  users: Array<UserModel>;
  applicationEvents: Array<ApplicationEventModel>;
  subcriptions: Array<NotificationSubcriptionModel>;
}

export const ADD_MESSAGES = "ADD_MESSAGES";
export interface addMessages {
  type: typeof ADD_MESSAGES;
  payload: Array<MessageModel>;
}

export const ADD_MESSAGE = "ADD_MESSAGE";
export interface addMessage {
  type: typeof ADD_MESSAGE;
  payload: MessageModel;
}

export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export interface removeMessage {
  type: typeof REMOVE_MESSAGE;
  payload: number;
}

export const ADD_NOTIFICATIONS = "ADD_NOTIFICATIONS";
export interface addNotifications {
  type: typeof ADD_NOTIFICATIONS;
  payload: Array<NotificationModel>;
}

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export interface addNotification {
  type: typeof ADD_NOTIFICATION;
  payload: NotificationModel;
}

export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export interface removeNotification {
  type: typeof REMOVE_NOTIFICATION;
  payload: number;
}

export const REMOVE_NOTIFICATION_BY_CODE = "REMOVE_NOTIFICATION_BY_CODE";
export interface removeNotificationByCode {
  type: typeof REMOVE_NOTIFICATION_BY_CODE;
  payload: string;
}

export const ADD_USERS = "ADD_USERS";
export interface addUsers {
  type: typeof ADD_USERS;
  payload: Array<UserModel>;
}

export const ADD_APPLICATION_EVENTS = "ADD_APPLICATION_EVENTS";
export interface addApplicationEvents {
  type: typeof ADD_APPLICATION_EVENTS;
  payload: Array<ApplicationEventModel>;
}

export const ADD_SUBCRIPTIONS = "ADD_SUBCRIPTIONS";
export interface addSubcriptions {
  type: typeof ADD_SUBCRIPTIONS;
  payload: Array<NotificationSubcriptionModel>;
}

export const ADD_SUBCRIPTION = "ADD_SUBCRIPTION";
export interface addSubcription {
  type: typeof ADD_SUBCRIPTION;
  payload: NotificationSubcriptionModel;
}

export const REMOVE_SUBCRIPTION = "REMOVE_SUBCRIPTION";
export interface removeSubcription {
  type: typeof REMOVE_SUBCRIPTION;
  payload: NotificationSubcriptionModel;
}
export type messageActionTypes =
  | addMessages
  | addMessage
  | removeMessage
  | addNotifications
  | addNotification
  | removeNotification
  | removeNotificationByCode
  | addUsers
  | addApplicationEvents
  | addSubcriptions
  | addSubcription
  | removeSubcription;
