import { Dispatch } from "react";

import { ZebraScanResult } from "capacitor-elinkx-zebra-scanner/src/definitions";

import { EventCode, ScannerOption, ScanType } from "@elx-element/common/enums";
import { getConfigurationBoolValue, getConfigurationStringValue } from "@elx-element/common/envconf";
import { dispatchScannerEvent } from "@elx-element/common/events/scanner";
import { IScan, ScannerControl } from "@elx-element/common/events/types";
import { throwSpecificError } from "@elx-element/common/logger";
import { getLastGpsLocationToString } from "@elx-element/common/storage";

import { PluginListenerHandle, Plugins } from "@capacitor/core";

import * as CoreStoreTypes from "../../store/core/types";

import { configureScanner } from "../../store/core/action";
import store from "../../store/index";

import { WebContainerErrors } from "../../enums";

const { ZebraScanner } = Plugins;
const debug = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_SCANNER_DEBUG");
const zebraScannerDecoders = getConfigurationStringValue(window.env.webcontainer, "ZEBRA_SCANNER_DECODERS");
const zebraScannerContinualWork = getConfigurationBoolValue(window.env.webcontainer, "ZEBRA_SCANNER_CONTINUAL_WORK");

// handle eventu skenování pro potřebu ukončení naslouchání, v momentě kdy není zapnuto kontinuální snímání
let zebraScannerHandle: PluginListenerHandle;

/**
 * Handler změny state s nastavením Zebra skeneru
 * @param settings - nastavení skeneru ScannerControl
 * @param dispatch
 */
export const handleZebraScannerControlEvent = (
  settings: CustomEventInit<ScannerControl>,
  dispatch: Dispatch<CoreStoreTypes.configureScanner | CoreStoreTypes.clearScanner | CoreStoreTypes.setScannerLastScan>
) => {
  if (debug) {
    console.debug(`nastavení skeneru:${JSON.stringify(settings.detail!)}`);
  }

  // uložíme poslední známé nastavení se kterým voláme řídící eventu (aby se při vypnutí v scanner.tsx vypínal správný modul)
  dispatch(configureScanner(settings.detail!));

  // Příchozí prametry
  // settings.detail!.active (boolean)
  // settings.detail!.name   (string | undefined)
  // settings.detail!.regex  (string | undefined)
  // settings.detail!.type   (Enums.ScanType (qr, ocr, photo, barcode, zebraBarcode, nfcMessage))

  // aktivace a deaktivace nativního Zebra vyplňování naskenovaných hodnot do focusovaných formulářových polí.
  if (
    settings.detail!.type === ScanType.zebraBarcode &&
    settings.detail!.scannerOptions !== undefined &&
    settings.detail!.scannerOptions.find(
      opt => opt === ScannerOption.enableZebraKeystrokeOutput || opt === ScannerOption.disableZebraKeystrokeOutput
    )
  ) {
    // zapnutí automatického skenování do označeného formulářového řádku
    if (settings.detail!.scannerOptions.find(opt => opt === ScannerOption.enableZebraKeystrokeOutput)) {
      ZebraScanner.setDataWedgeOptions({ keystroke_output_enabled: true }).then(() => {
        if (debug) {
          console.debug(`Zebra scaner: Native scaning behaviour scanner like a keyboard was enabled`);
        }
      });
    }
    // vypnutí automatického skenování do označeného formulářového řádku
    if (settings.detail!.scannerOptions.find(opt => opt === ScannerOption.disableZebraKeystrokeOutput)) {
      ZebraScanner.setDataWedgeOptions({ keystroke_output_enabled: false }).then(() => {
        if (debug) {
          console.debug(`Zebra scaner: Native scaning behaviour scanner like a keyboard was disabled`);
        }
      });
    }
  }

  // posluchač výsledků skenování se nachází v src\core\app.tsx nebo zde, dle nastavení zebraScannerContinualWork
  if (settings.detail!.active && settings.detail!.type === ScanType.zebraBarcode) {
    // v případě, že je zebra spuštěna nekontinuálně je listener inicializován přímo zde
    if (!zebraScannerContinualWork) {
      zebraScannerHandle = ZebraScanner.addListener("zebraScanEvent", (result: ZebraScanResult) =>
        scanResultFunction(result, settings.detail!, dispatch, onScanPerformed, onScanFinished)
      );
    }

    // nastartování scanneru a spuštění skenování
    try {
      ZebraScanner.toggleSoftScanTrigger().then(() => {
        if (debug) {
          console.debug("Zebra scaner: Software scan button pressed");
        }
      });
    } catch (ex) {
      throwSpecificError(
        WebContainerErrors.zebraSoftwareScanError,
        "Zebra scanner: software trigger error.",
        ex as string
      );
    }
  }
  // v případě, že není zebra spuštěna kontinuálně musím zde ukončit listener
  else if (!zebraScannerContinualWork && zebraScannerHandle != null) {
    zebraScannerHandle.remove();
  }
};

/**
 *  Vytvoření profilu DataWedge a nastavení defaultních dekodérů
 */
export const createDataWedgeProfile = async () => {
  // todo: zde vyčíst konfiguraci dekodérů pro danou aplikaci
  try {
    await ZebraScanner.createProfile();
    if (debug) {
      console.debug("Zebra scanner: DataWedge profile created");
    }

    await ZebraScanner.setDecodersFromConfigString({ configString: zebraScannerDecoders });
    if (debug) {
      console.debug(`Zebra scanner: default encoders set ${zebraScannerDecoders}`);
    }
  } catch (ex) {
    throwSpecificError(
      WebContainerErrors.zebraProfileAndDecoderError,
      "Zebra scanner: profile or decoder setup error.",
      ex as string
    );
  }
};

/**
 * Po naskenování hodnoty volá event ScannerEvents.dispatchScannerEvent(event: Common.EventModels.IScanEvent)
 * Cílový modul událost zpracuje a může sám zavřít skenování, nebo nereagovat.
 * Pokud nereaguje pak může uživatel ručně vnutit naskenovaný kód tlačítkem "Použít".
 */
export const onScanPerformed = (scan: IScan) => {
  dispatchScannerEvent({ type: EventCode.scanPerformed, scan });
};

/**
 * Po naskenování hodnoty volá event ScannerEvents.dispatchScannerEvent(event: Common.EventModels.IScanEvent)
 * Cílový modul zpracuje událost ,scanner se uzavírá automaticky skrz typ události Enums.EventCode.scanFinished
 */
export const onScanFinished = (scan: IScan) => {
  dispatchScannerEvent({ type: EventCode.scanFinished, scan });
};

/**
 * obsluha události naskenování nového výsledku
 * pro OCR se provádí ve třech fázích - 1.nahrazení výsledku dle kolekce RegExp pravidel, 2.rozpoznání obsahu dle kontrolního RegExp, 3.vyhodnocení výsledku
 * @param result - výsledek skenování typu scanResult
 * @param settings - state nastavení scaneru
 * @param onScanCompleted - funkce pro uložení výsledku skenování
 * @param onScanFinished - funkce pro uložení výsledku skenování s uzavřením scanneru
 */
export const scanResultFunction = (
  result: ZebraScanResult,
  settings: ScannerControl,
  dispatch: Dispatch<CoreStoreTypes.configureScanner>,
  onScanCompleted: (scan: IScan) => void,
  // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-unused-vars
  onScanFinished: (scan: IScan) => void
) => {
  if (debug) {
    console.debug(`Zebra scanned value: ${JSON.stringify(result)}`);
    console.debug(`Zebra nastavení: ${JSON.stringify(settings)}z controlu: ${settings.name}`);
  }

  // zebra scanner může při kontinuálním snímání získat skenované pole pouze ze state, při stisku sw tlačítka ukládá do state to, co se aktuálně snažíme skenovat.
  // (Nevytváří se nový posluchač událostí skeneru, proto je při naskenování hodnoty v poli které se skenujeme nastavena hodnota undefined)
  // sw tlačítko ale vždy uloží do state informaci o tom, co právě teď skenuje, a po skenování by se tato hodnota měla vymazat. (pokud je zaplé kontinuální snímání)
  // po skenování by se měl předat prázdný výsledek, aby na to mohl strana klienta mohla zareagovat a odstranit listener těchto událostí
  const state = store.getState();

  // pokud nejde o výsledek informující o stavu skeneru
  if (
    (result.result === "ok" && result.type !== "statusInfo") ||
    (result.type === "statusInfo" && result.data.indexOf("WAITING") !== -1)
  ) {
    // získám poslední známou polohu z localStorage a sestavým výsledný objek
    const scan: IScan = {
      type: ScanType.zebraBarcode,
      value: result.data.indexOf("WAITING") === -1 ? result.data : undefined,
      gps: getLastGpsLocationToString(),
      name: settings.name !== undefined ? settings.name : state.core.scannerSettings?.name,
    };

    // předání výsledku registrovaným posluchačům
    onScanCompleted(scan);

    // náhlada za nativní funkci skeneru, kdy skenuju zebra skenerem bez definovaného name prvku, tedy pomocí hw tlačítka
    if (scan.name === undefined && scan.value !== undefined) {
      if (debug) {
        console.debug("Skenuju z pole undefined (name), hledám první pole skenovatelné zebra skenerem");
      }
      let selectedHTMLInput: HTMLInputElement | undefined;

      // 1. pokus o zjištění provku který má aktuálně focus, ten může být jen jeden
      const { activeElement } = document;
      if (activeElement && activeElement instanceof HTMLInputElement) {
        selectedHTMLInput = activeElement as HTMLInputElement;
        if (debug) {
          console.debug(`zebraScanner: vkladam dle focusu ${selectedHTMLInput?.name}`);
        }
      } else {
        // 2. žádný prvek nemá focus, dohledám ve stránce elementy typu data-scanableOnZebra
        const scanableElements = document.querySelectorAll("[data-ExternalScanPriority]");
        if (scanableElements && scanableElements.length > 0) {
          let minPriority: number = Number.MAX_VALUE;
          // zjištění pole s nejnižší prioritou
          // eslint-disable-next-line no-restricted-syntax
          for (const oneControl of Array.from(scanableElements)) {
            if (oneControl && oneControl instanceof HTMLInputElement) {
              const oneHTMLInputElement = oneControl as HTMLInputElement;
              const strPriority = oneHTMLInputElement.getAttribute("data-ExternalScanPriority");

              if (strPriority) {
                const priority = parseInt(strPriority);
                if (priority < minPriority) {
                  minPriority = priority;
                  selectedHTMLInput = oneHTMLInputElement;
                }
              }
            }
          }

          if (selectedHTMLInput && debug) {
            console.debug(
              `zebraScanner: vkladam dle HTML atributu data-ExternalScanPriority ${selectedHTMLInput?.name}, s prioritou ${minPriority}`
            );
          }
        }
      }

      if (selectedHTMLInput && selectedHTMLInput.type === "text") {
        // jde o textové pole vrazím sem napřímo naši naskenovanou hodnotu + odpálíme událost změny hodnoty (aby mohl progarm zareagovat)
        // viz https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js
        if (debug) {
          console.debug(`zebraScanner: vkládám hodnotu ${scan.value} do elementu ${selectedHTMLInput?.name}`);
        }
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;
        nativeInputValueSetter?.call(selectedHTMLInput, scan.value);

        const ev = new CustomEvent("input", {
          bubbles: true,
          detail: { scan: true },
        });
        selectedHTMLInput.dispatchEvent(ev);
      }
    }
  }

  // pokud příjde událost waiting, vyresetuju informaci o poli, které aktuálně skenuju a to tím, že přepíšu typ skenování
  if (
    settings.type === ScanType.zebraBarcode &&
    result.result === "ok" &&
    result.type === "statusInfo" &&
    result.data.indexOf("WAITING") !== -1
  ) {
    if (zebraScannerContinualWork) {
      const sc = new ScannerControl();
      sc.active = false;
      sc.regex = undefined;
      sc.replaceRules = undefined;
      sc.name = undefined;
      sc.type = ScanType.zebraBarcode;

      // přepíšeme nastavení tak aby name měl hodnotu undefined
      dispatch(configureScanner(sc));

      if (debug) {
        console.debug("Zebra nastavení skenování se mění na defaultní");
      }
    }
  }
};
