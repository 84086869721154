import React, { useEffect } from "react";

import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";

import { getConfigurationNumberValue, getConfigurationStringValue } from "@elx-element/common/envconf";

import { selectPlatform } from "../../store/selectors";

import useAppStyles from "../app/styles";

import TryLaterPage from "./TryLaterPage";

import userManager from "../../auth/userManager";
import { checkServerAvailability } from "../app/core";
import * as CoreUser from "../user/core";

const LoginPage = () => {
  const { classes } = useAppStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const platform = useSelector(selectPlatform);
  const snackbar = useSnackbar();

  // Timeout komponenty. Pokud loading probíhá déle než stanovaný čas, zobrazí se chyba.
  const [timeoutOccured, setTimeoutOccured] = React.useState<boolean>(false);
  let timer: any;
  const timeout = getConfigurationNumberValue(window.env.webcontainer, "KEYCLOAK_CALLBACK_TIMEOUT");
  const handleLoginTimeout = () => {
    setTimeoutOccured(true);
    snackbar.closeSnackbar(); // vymaže zásobník chybových notifikací.
  };
  useEffect(() => {
    timer = setTimeout(handleLoginTimeout, timeout);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(
    () => {
      if (platform !== undefined) {
        // Proběhne test konektivity na keycloak. V případě že server neodpoví kódem 200, zobrazí se chyba.
        checkServerAvailability(getConfigurationStringValue(window.env.webcontainer, "AUTH_URL")).then(async status => {
          if (status === 200) {
            setLoading(true);
            await userManager.removeUser();
            CoreUser.onLogin(platform);
          } else {
            setError(true);
          }
        });
      }
    },
    [platform] /* Pokud se změní tento parametr, kod se provede znovu */
  );

  return (
    <div className={classes.centeredContent}>
      {loading && !timeoutOccured && <CircularProgress />}
      {(error || timeoutOccured) && <TryLaterPage />}
    </div>
  );
};

export default LoginPage;
