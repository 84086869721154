import {
  ApplicationEventModel,
  MessageModel,
  NotificationModel,
  NotificationSubcriptionModel,
  UserModel,
} from "../../types.generated";
import * as ActionTypes from "./types";

/**
 * Akce pro uložení příchozích zpráv do storu
 * @param messages
 */
export function addMessages(messages: Array<MessageModel>): ActionTypes.addMessages {
  return {
    type: ActionTypes.ADD_MESSAGES,
    payload: messages,
  };
}

/**
 * Akce pro uložení jedné příchozí zprávy do storu
 * @param message
 */
export function addMessage(message: MessageModel): ActionTypes.addMessage {
  return {
    type: ActionTypes.ADD_MESSAGE,
    payload: message,
  };
}

/**
 * Akce pro odstranění vybrané zprávy ze storu
 * @param messageId
 */
export function removeMessage(messageId: number): ActionTypes.removeMessage {
  return {
    type: ActionTypes.REMOVE_MESSAGE,
    payload: messageId,
  };
}

/**
 * Akce pro uložení příchozích notifikací do storu
 * @param notifications
 */
export function addNotifications(notifications: Array<NotificationModel>): ActionTypes.addNotifications {
  return {
    type: ActionTypes.ADD_NOTIFICATIONS,
    payload: notifications,
  };
}

/**
 * Akce pro uložení jedné příchozí notifikace do storu
 * @param notification
 */
export function addNotification(notification: NotificationModel): ActionTypes.addNotification {
  return {
    type: ActionTypes.ADD_NOTIFICATION,
    payload: notification,
  };
}

/**
 * Akce pro odstranění vybrané notifikace ze storu
 * @param notificationId
 */
export function removeNotification(notificationId: number): ActionTypes.removeNotification {
  return {
    type: ActionTypes.REMOVE_NOTIFICATION,
    payload: notificationId,
  };
}

/**
 * Akce pro odstranění vybrané notifikace ze storu
 * @param notificationCode
 */
export function removeNotificationByCode(notificationCode: string): ActionTypes.removeNotificationByCode {
  return {
    type: ActionTypes.REMOVE_NOTIFICATION_BY_CODE,
    payload: notificationCode,
  };
}

/**
 * Akce pro uložení seznamu uživatelů do storu
 * @param users
 */
export function addUsers(users: Array<UserModel>): ActionTypes.addUsers {
  return {
    type: ActionTypes.ADD_USERS,
    payload: users,
  };
}

/**
 * Akce pro uložení seznamu typů notifikací
 * @param users
 */
export function addApplicationEvents(
  applicationEvents: Array<ApplicationEventModel>
): ActionTypes.addApplicationEvents {
  return {
    type: ActionTypes.ADD_APPLICATION_EVENTS,
    payload: applicationEvents,
  };
}

/**
 * Akce pro uložení seznamu odebíraných notifikací
 * @param users
 */
export function addSubcriptions(subcriptions: Array<NotificationSubcriptionModel>): ActionTypes.addSubcriptions {
  return {
    type: ActionTypes.ADD_SUBCRIPTIONS,
    payload: subcriptions,
  };
}
