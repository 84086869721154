import { WebContainerPlatform } from "@elx-element/common/enums";
import { IScan, NfcWriterControl, ScannerControl } from "@elx-element/common/events/types";

import { DashboardSettingsModel } from "../../types.generated";
import * as ActionTypes from "./types";

import * as CoreModels from "../../models";
import { ModuleInterface } from "../../models";

/**
 * Akce pro uložení příznaku otevřeného menu do storu
 * @param open
 */
export function setMainMenuOpen(open: boolean): ActionTypes.setMainMenuOpen {
  return {
    type: ActionTypes.SET_MAIN_MENU_OPEN,
    payload: open,
  };
}

/**
 * Akce pro uložení ID aktuálně spuštěného modulu do storu
 * @param activeModuleId
 */
export function setActiveModule(activeModuleId: string | undefined): ActionTypes.setActiveModule {
  return {
    type: ActionTypes.SET_ACTIVE_MODULE,
    payload: activeModuleId || undefined,
  };
}

/**
 * Akce pro uložení seznamu zaregistrovaných modulů do storu
 * @param registeredModules
 */
export function setRegisteredModules(
  registeredModules: Array<CoreModels.RegisteredModule>
): ActionTypes.setRegisteredModules {
  return {
    type: ActionTypes.SET_REGISTERED_MODULES,
    payload: registeredModules,
  };
}

/**
 * Akce pro uložení příznaku kompletního načtení všech registrací modulů
 */
export function setRegisteredModulesLoaded(): ActionTypes.setRegisteredModulesLoaded {
  return {
    type: ActionTypes.SET_REGISTERED_MODULES_LOADED,
  };
}

/**
 * Uložení registrované metody
 * @param moduleId
 * @param methods
 */
export function setModuleInterface(
  moduleId: string,
  moduleInterface: ModuleInterface,
  version: string | undefined,
  buildNumber: string | undefined
): ActionTypes.setModuleInterface {
  return {
    type: ActionTypes.SET_MODULE_INTERFACE,
    payload: {
      moduleId,
      interface: moduleInterface,
      version,
      buildNumber,
    },
  };
}

/**
 * Potvrzení registrované metody - existuje platná komunikace
 * @param moduleId
 * @param method
 */
export function confirmModuleInterfaceEvent(
  moduleId: string,
  event: CoreModels.ModuleInterfaceEvent
): ActionTypes.confirmModuleInterfaceEvent {
  return {
    type: ActionTypes.CONFIRM_MODULE_INTERFACE_EVENT,
    payload: {
      moduleId,
      event,
    },
  };
}

/**
 * Akce pro uložení použité platformy
 * @param users
 */
export function setPlatform(platform: WebContainerPlatform): ActionTypes.setPlatform {
  return {
    type: ActionTypes.SET_PLATFORM,
    payload: platform,
  };
}

/**
 * Akce pro uložení informace zda je použitá platforma aktivní (aplikace není pozastavena)
 * @param users
 */
export function setPlatformIsActive(isActive: boolean): ActionTypes.setPlatformIsActive {
  return {
    type: ActionTypes.SET_PLATFORM_IS_ACTIVE,
    payload: isActive,
  };
}

/**
 * Příznak existence konfiguračního souboru
 * @param users
 */
export function setModuleConfigExists(moduleId: string): ActionTypes.setModuleConfigExists {
  return {
    type: ActionTypes.SET_MODULE_CONFIG_EXISTS,
    payload: moduleId,
  };
}

/**
 * Nastavení zobrazení komponenty scanneru
 * @param settings
 */
export function configureScanner(settings: ScannerControl): ActionTypes.configureScanner {
  return {
    type: ActionTypes.CONFIGURE_SCANNER,
    payload: settings,
  };
}

/**
 * Zavření skeneru, zrušení nastavení
 */
export function clearScanner(): ActionTypes.clearScanner {
  return {
    type: ActionTypes.CLEAR_SCANNER,
  };
}

/**
 * Zavření nfc writeru, zrušení nastavení
 */
export function clearNfcWriter(): ActionTypes.clearNfcWriter {
  return {
    type: ActionTypes.CLEAR_NFC_WRITER,
  };
}

/** Uložení posledního naskenovaného kódu */
export function setScannerLastScan(scan: IScan | undefined): ActionTypes.setScannerLastScan {
  return {
    type: ActionTypes.SET_SCANNER_LAST_SCAN,
    payload: scan,
  };
}

/**
 * Nastavení zobrazení komponenty NFC zápisu
 * @param settings
 */
export function configureNfcWriter(settings: NfcWriterControl): ActionTypes.configureNfcWriter {
  return {
    type: ActionTypes.CONFIGURE_NFC_WRITER,
    payload: settings,
  };
}

/**
 * Kolekce nastavení dashboardu
 * @param settings
 */
export function setDashboardSettings(settings: Array<DashboardSettingsModel>): ActionTypes.setDasboardSettings {
  return {
    type: ActionTypes.SET_DASHBOARD_SETTINGS,
    payload: settings,
  };
}

/**
 * setOpenErrorDetailReference
 * @param openErrorDetailReference
 * @returns
 */
export function setOpenErrorDetailReference(
  openErrorDetailReference: string | undefined
): ActionTypes.setOpenErrorDetailReference {
  return {
    type: ActionTypes.SET_OPEN_ERROR_DETAIL_REFERENCE,
    payload: openErrorDetailReference,
  };
}

/**
 * setModuleUpdated
 * @param isUpdated
 * @return
 */
export function setModuleUpdated(isUpdated: boolean): ActionTypes.setModuleUpdated {
  return {
    type: ActionTypes.SET_MODULE_UPDATED,
    payload: isUpdated,
  };
}

/**
 * setWebContainerUpdated
 * @param isUpdated
 * @return
 */
export function setWebContainerUpdated(isUpdated: boolean): ActionTypes.setWebContainerUpdated {
  return {
    type: ActionTypes.SET_WEBCONTAINER_UPDATED,
    payload: isUpdated,
  };
}
