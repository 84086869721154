import { makeStyles } from "tss-react/mui";

import { buttonClasses } from "@mui/material/Button";
import { dialogActionsClasses } from "@mui/material/DialogActions";
import { dialogContentClasses } from "@mui/material/DialogContent";
import { dialogTitleClasses } from "@mui/material/DialogTitle";

const useStyles = makeStyles()(theme => ({
  dialogPaper: {
    [`& .${dialogTitleClasses.root}`]: {
      padding: theme.spacing(2),
    },
    [`& .${dialogContentClasses.root}`]: {
      padding: theme.spacing(1, 2),
    },
    [`& .${dialogActionsClasses.root}`]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 600,
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: 500,
    },
  },
  xsFullscreenDialog: {
    [theme.breakpoints.down("xs")]: {
      margin: "0;",
      height: "100%",
      position: "absolute",
      maxHeight: "100%",
      borderRadius: 0,
      width: "100%",
    },
  },
  dialogTitle: {
    "& h2": {
      fontSize: "1.35rem",
    },
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      "& h2": {
        marginBottom: 0,
      },
    },
  },
  dialogContent: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3),
    },
  },
  dialogActions: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
      [`& .${buttonClasses.startIcon}`]: {
        marginRight: theme.spacing(0.5),
      },
    },
  },
  contentItem: {
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
