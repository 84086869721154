import { Dispatch } from "react";

import { NfcWriterControl, ScannerControl } from "@elx-element/common/events/types";

import * as CoreStoreTypes from "../../store/core/types";

import { clearNfcWriter, clearScanner, configureNfcWriter, configureScanner } from "../../store/core/action";

import { hideScannerPageBackground, showScannerPageBackground } from "./scannerCore";
/**
 * Handler změny state s nastavením NFC skeneru
 * @param settings - nastavení skeneru ScannerControl
 * @param dispatch
 */
export const handleNfcScannerControlEvent = (
  settings: CustomEventInit<ScannerControl>,
  dispatch: Dispatch<CoreStoreTypes.configureScanner | CoreStoreTypes.clearScanner | CoreStoreTypes.setScannerLastScan>
) => {
  // spuštění skeneru
  if (settings.detail!.active) {
    hideScannerPageBackground();
    dispatch(configureScanner(settings.detail!));
  } else {
    // zavření skeneru
    scannerClose(dispatch);
  }
};
/** Logika pro obsluhu NFC skeneru je v komponentě components/nfcScanner.tsx */

/**
 * Handler změny state s nastavením NFC writeru
 * @param settings - nastavení writeru NfcWriterControl
 * @param dispatch
 */
export const handleNfcWriterControlEvent = (
  settings: CustomEventInit<NfcWriterControl>,
  dispatch: Dispatch<CoreStoreTypes.configureNfcWriter | CoreStoreTypes.clearNfcWriter>
) => {
  // spuštění writeru
  if (settings.detail!.active) {
    hideScannerPageBackground();
    dispatch(configureNfcWriter(settings.detail!));
  } else {
    // zavření skeneru
    writerClose(dispatch);
  }
};
/** Logika pro obsluhu NFC writeru je v komponentě components/nfcWriter.tsx */

/**
 * Zavření UI scanneru
 * @param dispatch
 */
export const scannerClose = (dispatch: Dispatch<CoreStoreTypes.clearScanner>) => {
  dispatch(clearScanner());
  showScannerPageBackground();
};

/**
 * Zavření UI writeru
 * @param dispatch
 */
export const writerClose = (dispatch: Dispatch<CoreStoreTypes.clearNfcWriter>) => {
  dispatch(clearNfcWriter());
  showScannerPageBackground();
};
