export enum ModuleInterfaceDirectionFlag {
  in = "in",
  out = "out",
}

export enum WebContainerErrors {
  unsupportedCommunicationType = "unsupportedCommunicationType",
  executeActionFailed = "executeActionFailed",
  unsupportedActionData = "unsupportedActionData",
  moduleNotPermitted = "moduleNotPermitted",
  signalRConnectionError = "signalRConnectionError",
  signalRError = "signalRError",
  zebraSoftwareScanError = "zebraSoftwareScanError",
  zebraProfileAndDecoderError = "zebraProfileAndDecoderError",
  scannerOrientationChangeError = "scannerOrientationChangeError",
  scannerRegexpReplaceError = "scannerRegexpReplaceError",
  scannerRegexpError = "scannerRegexpError",
  scannerScanAgainError = "scannerScanAgainError",
  scannerNotFoundScanError = "scannerNotFoundScanError",
  scannerScanError = "scannerScanError",
  scannerShutdownError = "scannerShutdownError",
}

export enum SystemActionCode {
  userlistchanged = "element.system.webcontainerapi.usermanagement.userlistchanged",
}

/** Role */
export enum ApplicationRole {
  user = "webcontainer.user",
  admin = "webcontainer.admin",
  elxadmin = "webcontainer.elxadmin",
}

/**
 * Slouží pro nastavení atributu application u vygenerované notifikace, na jejímž základě může
 * WebContainer notifikaci identifikovat a nabízet konkrétní akce
 */
export enum WebContainerApplication {
  licenses = "licenses",
}
