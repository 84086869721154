import { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";

import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";

import { baseApiMan, SwaggerResponse } from "@elx-element/common/apiClient/apiMan";
import { CommonErrors, Notification } from "@elx-element/common/enums";
import { dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import { NotificationEventModel } from "@elx-element/common/events/types";
import { LogRecord } from "@elx-element/common/logger/types";
import { getLogFromSessionStorage } from "@elx-element/common/logger/utils";
import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";
import BaseTextField from "@elx-element/ui/Inputs/BaseTextField";

import { mdiClose } from "@mdi/js";

import { IssueCreationResultModel, WebContainerClient } from "../../types.generated";

import { selectCulture, selectTexts } from "../../store/selectors";

import useStyles from "./styles";

interface JiraContentProps {
  error: LogRecord;
  onClose: () => void;
  onBack: () => void;
}

const JiraComponent = ({ error, onBack, onClose }: JiraContentProps) => {
  const { classes } = useStyles();
  const culture = useSelector(selectCulture);
  const texts = useSelector(selectTexts);
  const [specification, setSpecification] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const abortController = useRef(new AbortController());
  useEffect(
    () => () => {
      abortController.current.abort();
    },
    []
  );

  const reportToJira = async () => {
    setLoading(true);
    await processReportToJira(
      error,
      specification,
      texts.ERROR_REPORTED,
      texts.ERROR_ALREADY_REPORTED,
      new WebContainerClient({ abortSignal: abortController.current.signal })
    );
    setLoading(false);
    onClose();
  };

  const processReportToJira = async (
    err: LogRecord,
    issueSpecification: string | undefined,
    reportedText: string,
    reportedAlreadyText: string,
    client: WebContainerClient
  ) => {
    const storageLog = getLogFromSessionStorage();
    /** !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
     *   POZOR - funkce types.generated.ts/selfRepairIssue byla ručně modifikována,
     *   doimplementoval jsem parametr body: any, protože ve swaggeru je to špatně definováno.
     *   ČEKÁM NA OPRAVU NA STRANĚ API.
     *   DO TÉ DOBY PŘI PŘEGENEROVÁNÍ TYPŮ JE NUTNÉ PARAMETR BODY ZPÁTKY PŘIDAT !!!
     *  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
    const response: SwaggerResponse<IssueCreationResultModel> | undefined = await baseApiMan(
      client.selfRepairIssue(err.errorCode ?? "", {
        shortDescription: issueSpecification,
        exception: JSON.stringify(err),
        errorStack: storageLog.length > 0 ? storageLog : undefined,
      })
    );
    if (response) {
      switch (response.status) {
        case 201: {
          dispatchNotificationEvent(
            new NotificationEventModel(reportedText, Notification.success, response.result.selfWebUrl)
          );
          break;
        }
        case 208: {
          dispatchNotificationEvent(
            new NotificationEventModel(reportedAlreadyText, Notification.warning, response.result.selfWebUrl)
          );
          break;
        }
        default:
          break;
      }
    }
  };

  return (
    <>
      <DialogTitle className={classes.errorTitle}>
        {texts.REPORT_TO_JIRA}
        <IconButton edge="start" color="inherit" aria-label="close" className={classes.closeButton} onClick={onClose}>
          <BaseIcon data={mdiClose} />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.errorContent}>
        <DialogContentText color="secondary">{texts.JIRA_SPECIFICATION}</DialogContentText>
        <BaseTextField
          culture={culture}
          autoFocus
          margin="dense"
          id="specification"
          label={texts.SPECIFICATION}
          fullWidth
          multiline
          minRows={3}
          value={specification}
          onChange={e => setSpecification(e.target.value !== "" ? e.target.value : undefined)}
        />
      </DialogContent>
      <DialogActions className={classes.errorFooter}>
        <Button
          onClick={() => {
            setSpecification(undefined);
            onBack();
          }}
          color="primary"
          variant="contained"
        >
          {texts.BACK}
        </Button>
        <Button
          disabled={(!specification && error.errorCode === CommonErrors.unknownError) || loading}
          startIcon={loading ? <CircularProgress size={16} color="inherit" /> : undefined}
          onClick={reportToJira}
          color="secondary"
          variant="contained"
        >
          {texts.SEND}
        </Button>
      </DialogActions>
    </>
  );
};

export default JiraComponent;
